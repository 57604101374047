import Input from '@components/input';
import Pagination from '@components/pagination';
import Modal from 'components/bases/shared/modal';
import Table from 'components/bases/shared/table';
import React, { useState, useEffect } from 'react';
import { EditModal } from './editModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFilter,
  getUserApi,
  setChooseList,
  getHandleAuthorityApi,
  exportAuthorityApi,
  getDeleteApi,
} from '@duck/pages/admin/authorizationListReducer/actions';
import { findIndex } from '@configs/findIndex';
import Button from '@components/button';
import { transformNumberPrinter } from '@configs/transformNumber';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { getFileCommonApi } from '@duck/pages/checkin/authorityListReducer/actions';
import Loading from '@components/loading';
import DeleteModal from '@components/modal/ModalDelete';
import { formatDateTime } from '@configs/formatDate';
import { SortOrder } from './SortOrder';

var imgFileExtensions = [
  '.jpeg',
  '.jpg',
  '.peg',
  '.tiff',
  '.png',
  '.svg',
  '.gif',
  '.bmp',
];
export function isImage(sFileName) {
  if (sFileName && sFileName.length > 0) {
    var isImg = false;
    for (var j = 0; j < imgFileExtensions.length; j++) {
      var sCurExtension = imgFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        isImg = true;
        break;
      }
    }
    return isImg;
  }
}
export function getFileExtension(sFileName) {
  if (sFileName) {
    let index = sFileName.lastIndexOf('.');
    if (sFileName.length > 0 && index > 0) {
      return sFileName.substr(index, sFileName.length - index).toLowerCase();
    } else return '';
  }
  return '';
}

export const TableList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [toggleModalDelete, setToggleModalDelete] = useState(false);
  const { filter, listChose, total, tableList } = useSelector(
    (state) => state.admin.authorizationList,
  );

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      dispatch(
        setFilter({
          identityNumber: '',
          status: 'all',
          pageSize: 10,
          pageNumber: 1,
          type: 'all',
          form: 'all',
        }),
      );
      dispatch(setChooseList([]));
    }
  }, [firstTime, dispatch]);
  useEffect(() => {
    console.log('filter', filter);
    if (firstTime === false) {
      dispatch(setChooseList([]));
      dispatch(getUserApi(filter));
    }
  }, [dispatch, firstTime, filter]);

  const areSelectedAll = () => {
    let res = true;
    for (let i in tableList) {
      if (findIndex(tableList[i], listChose) === -1) {
        res = false;
      }
    }
    return res;
  };

  const onSelectAll = () => {
    const listTmp = [...listChose];
    if (areSelectedAll()) {
      for (let i in tableList) {
        listTmp.splice(findIndex(tableList[i], listTmp), 1);
      }
    } else {
      for (let i in tableList) {
        if (findIndex(tableList[i], listChose) === -1)
          listTmp.push(tableList[i]);
      }
    }
    dispatch(setChooseList(listTmp));
  };

  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };

  const onSortTime = () => {
    console.log('firstaaaaaaaaaaaaaaaa');
    dispatch(
      setFilter({
        ...filter,
        pageNumber: 1,
        order: filter.order == 'asc' ? 'desc' : 'asc',
      }),
    );
  };

  const getListIds = () => {
    const listIds = listChose.map((item) => item._id);
    return listIds;
  };

  const choseCheckBox = (item) => {
    var newListChose = [...listChose];
    if (findIndex(item, newListChose) < 0) {
      newListChose = [];
      newListChose.push(item);
    } else {
      newListChose.splice(newListChose.indexOf(item), 1);
    }
    dispatch(setChooseList(newListChose));
  };
  const rejectAutho = () => {
    dispatch(getHandleAuthorityApi(listChose[0]._id, 'deny', filter));
  };

  const confirmAutho = () => {
    dispatch(getHandleAuthorityApi(listChose[0]._id, 'accept', filter));
  };
  const exportListAuthority = () => {
    dispatch(
      exportAuthorityApi({
        identityNumber: filter.identityNumber,
        form: filter.form,
        status: filter.status,
      }),
    );
  };
  return (
    <div className="table-container">
      {loading && <Loading title={t('Đang xử lý. Vui lòng đợi')} />}
      <div className="tool-container">
        <Button className="export-btn" onClick={exportListAuthority}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.11111 10.8235V11.1765C5.42633 9.35671 6.91111 8.38612 8.77778 8.35294V10.4706C8.77778 10.8595 9.17522 11.1765 9.66678 11.1765C9.94989 11.1765 10.1918 11.0649 10.3536 10.9005C11.857 9.46824 15 6.58824 15 6.58824C15 6.58824 11.857 3.70682 10.3528 2.25624C10.1698 2.09267 9.92357 2.00068 9.66678 2C9.17522 2 8.77778 2.31553 8.77778 2.70588V4.82353C5.15333 4.82353 4.11111 8.26188 4.11111 10.8235ZM1.77778 14H12.6667C12.8729 14 13.0708 13.9256 13.2166 13.7933C13.3625 13.6609 13.4444 13.4813 13.4444 13.2941V9.02635C12.928 9.50353 12.3836 10.0096 11.8889 10.4713V12.5882H2.55556V4.11765H8V2.70588H1.77778C1.5715 2.70588 1.37367 2.78025 1.22781 2.91263C1.08194 3.04501 1 3.22455 1 3.41176V13.2941C1 13.4813 1.08194 13.6609 1.22781 13.7933C1.37367 13.9256 1.5715 14 1.77778 14Z"
              fill="white"
            />
          </svg>
          {t('Xuất file excel')}
        </Button>
        <div className="list-handle" style={{ minHeight: '40px' }}>
          {tableList.length > 0 && (
            <Input.HandleButton
              type="select"
              select={areSelectedAll()}
              action={onSelectAll}
            />
          )}

          {listChose.length === 1 && (
            <Input.HandleButton
              type="edit"
              action={() => {
                setToggleModal(true);
              }}
            />
          )}
          {listChose.length > 0 && (
            <Input.HandleButton
              type="delete"
              action={() => {
                setToggleModalDelete(true);
              }}
            />
          )}
          {listChose.length > 0 && listChose[0].status === 'pending' && (
            <>
              <Input.HandleButton type="approve" action={confirmAutho} />
              <Input.HandleButton type="refuse" action={rejectAutho} />
            </>
          )}
        </div>
      </div>
      <div className="table">
        <Table
          customHeader={
            filter?.type === 'all' ? (
              <thead>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th colSpan={2} style={{ textAlign: 'center' }}>
                    {t('Thông tin người ủy quyền')}
                  </th>
                  <th colSpan={3} style={{ textAlign: 'center' }}>
                    {t('Thông tin người nhận ủy quyền')}
                  </th>
                  <th rowSpan={2}>{t('CP ủy quyền')}</th>
                  <th rowSpan={2}>{t('Loại ủy quyền')}</th>
                  <th rowSpan={2}>{t('File đính kèm')}</th>
                  <th rowSpan={2}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '90px',
                      }}
                    >
                      {t('Thời gian ủy quyền')}{' '}
                      <SortOrder onSort={onSortTime} />
                    </div>
                  </th>
                  <th rowSpan={2}>{t('Thời gian duyệt ủy quyền')}</th>
                  <th rowSpan={2}>{t('Người duyệt')}</th>
                  <th rowSpan={2}>{t('Trạng thái')}</th>
                </tr>
                <tr>
                  <th>{t('Họ tên')}</th>
                  <th>{t('CMND/CCCD')}</th>
                  <th>{t('Họ tên')}</th>
                  <th>{t('CMND/CCCD')}</th>
                  <th>{t('Số điện thoại')}</th>
                </tr>
              </thead>
            ) : filter.type === 'shareholder' ? (
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', paddingLeft: '25px' }}>
                    {t('Họ tên')}
                  </th>
                  <th>{t('CMND/CCCD')}</th>
                  <th>{t('CP sở hữu')}</th>
                  <th>{t('CP ủy quyền đã xử lý')}</th>
                  <th>{t('CP chờ xử lý')}</th>
                  <th>{t('CP còn lại')}</th>
                </tr>
              </thead>
            ) : (
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', paddingLeft: '25px' }}>
                    {t('Họ tên')}
                  </th>
                  <th>{t('CMND/CCCD')}</th>
                  <th>{t('CP sở hữu')}</th>
                  <th>{t('CP nhận ủy quyền')}</th>
                  <th>{t('Tổng số CP đại diện')}</th>
                </tr>
              </thead>
            )
          }
          // minWidth="1200px"
          minWidth={filter.type === 'all' ? '1200px' : '900px'}
        >
          {tableList?.length > 0 ? (
            filter.type === 'all' ? (
              <>
                {tableList.map((item, index) => {
                  return (
                    <tr
                      onClick={() => choseCheckBox(item)}
                      className={
                        findIndex(item, listChose) >= 0 ? 'active' : ''
                      }
                    >
                      <td className="center">
                        <Input.Checkbox
                          checked={
                            findIndex(item, listChose) >= 0 ? true : false
                          }
                        />
                      </td>
                      <td> {item.shareholder ? item.shareholder.name : ''} </td>
                      <td>
                        {item.shareholder
                          ? item.shareholder.identityNumber
                          : ''}
                      </td>
                      <td>{item.name} </td>
                      <td>{item.identityNumber} </td>
                      <td>{item.phone} </td>
                      <td>{transformNumberPrinter(item.sharesNumber)} </td>
                      <td>
                        {item.form === 'document'
                          ? t('Tài liệu')
                          : t('Trực tuyến')}
                      </td>
                      <td className="center">
                        {item?.files?.length > 0 ? (
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {item?.files?.map((el, i) => {
                              return (
                                <a
                                  className="image-link"
                                  rel="noopener noreferrer"
                                  href={`${
                                    process.env.REACT_APP_URL_RESULT + '/' + el
                                  }`}
                                  target="__blank"
                                  key={i}
                                  style={{
                                    color: '#2f80ed',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  {isImage(el)
                                    ? `anhdinhkem${getFileExtension(el)}`
                                    : `filedinhkem${getFileExtension(el)}`}
                                </a>
                              );
                            })}
                          </div>
                        ) : (
                          <span
                            style={{ cursor: 'pointer', display: 'flex' }}
                            onClick={() => {
                              dispatch(getFileCommonApi(item._id, setLoading));
                            }}
                          >
                            <span style={{ marginRight: '5px' }}>
                              filedinhkem
                            </span>
                          </span>
                        )}
                      </td>
                      <td>{formatDateTime(item.createdAt)}</td>
                      <td>{formatDateTime(item.approvementTime)}</td>
                      <td>{item.approver?.name}</td>
                      <td
                        className={`${
                          item.status === 'accept'
                            ? 'success'
                            : item.status === 'pending'
                            ? 'pending'
                            : 'fail'
                        }`}
                      >
                        {item.status === 'accept'
                          ? t('Đã duyệt')
                          : item.status === 'pending'
                          ? t('Chờ xử lý')
                          : t('Từ chối')}
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {tableList.map((item, index) => {
                  return (
                    <tr>
                      <td style={{ textAlign: 'left', paddingLeft: '25px' }}>
                        {' '}
                        {filter.type === 'shareholder'
                          ? item.shareholder && item.shareholder.name
                          : item.name}{' '}
                      </td>
                      <td>
                        {filter.type === 'shareholder'
                          ? item.shareholder && item.shareholder.identityNumber
                          : item.identityNumber}
                      </td>
                      {/* <td>{transformNumberPrinter(item.sharesNumber)} </td> */}
                      <td>
                        {filter.type === 'shareholder'
                          ? item.shareholder &&
                            transformNumberPrinter(
                              item.shareholder.sharesNumber,
                            )
                          : transformNumberPrinter(item.sharesNumber)}
                      </td>
                      {filter.type === 'shareholder' ? (
                        <>
                          <td>{transformNumberPrinter(item.accept)}</td>
                          <td>{transformNumberPrinter(item.pending)}</td>
                          <td>
                            {item.shareholder
                              ? transformNumberPrinter(
                                  Number(item.shareholder.sharesNumber) -
                                    Number(item.accept) -
                                    Number(item.pending),
                                )
                              : 0}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {transformNumberPrinter(
                              item.sharesAuthorizedNumber,
                            )}
                          </td>
                          <td>
                            {transformNumberPrinter(
                              Number(item.sharesAuthorizedNumber) +
                                Number(item.sharesNumber),
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </>
            )
          ) : (
            <tr>
              <td colSpan={100} className="center">
                {' '}
                {t('Chưa có dữ liệu')}{' '}
              </td>
            </tr>
          )}
        </Table>
        {tableList.length > 0 && (
          <Pagination
            handleChangePageSize={getPageSize}
            current={filter.pageNumber}
            total={total}
            pageSize={filter.pageSize}
            onChange={getPage}
          />
        )}
      </div>

      <Modal
        isOpen={toggleModal}
        toggleModal={setToggleModal}
        title={t('Sửa thông tin')}
        overwriteModalStyle={{
          maxWidth: '816px',
        }}
      >
        <EditModal setToggleModal={setToggleModal} />
      </Modal>
      <DeleteModal
        title={t('Xóa các nội dung')}
        isOpen={toggleModalDelete}
        toggleModal={setToggleModalDelete}
        onSubmit={() => {
          dispatch(getDeleteApi(getListIds(), filter));
        }}
      />
    </div>
  );
};
