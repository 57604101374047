import React, { useEffect, Fragment } from 'react';
import {
  getUserApi,
  setChooseList,
  setFilter,
  getListAccount,
  setTotal,
} from '../../../../states/duck/pages/admin/shareholderListReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex } from '../../../bases/configs';
// import PaginationTable from '../../../bases/shared/paginationTable';
import { PAGE_SIZE } from '../../../../states/common/constants';
import { transformNumberPrinter } from './../../../bases/configs';
import Table from '@components/table';
import Input from '@components/input';
import Pagination from '@components/pagination';
const TableComponent = () => {
  const dispatch = useDispatch();
  const table = useSelector((state) => state.admin.shareholderList.tableList);
  const total = useSelector((state) => state.admin.shareholderList.total);
  const listChose = useSelector(
    (state) => state.admin.shareholderList.listTopicChoose,
  );
  const filter = useSelector((state) => state.admin.shareholderList.filter);
  useEffect(() => {
    dispatch(getUserApi(filter));
    dispatch(setChooseList([]));
  }, [dispatch, filter]);
  const theads = [
    { name: '' },
    { name: 'STT' },
    { name: 'Mã CĐ' },
    { name: 'Họ tên Cổ đông' },
    { name: 'CMND/CCCD' },
    { name: 'Ngày cấp' },
    { name: 'Nơi cấp' },
    { name: 'Số điện thoại' },
    {
      name: 'Số lượng CP',
      sortUp: () => onSort('asc'),
      sortDown: () => onSort('desc'),
    },
    { name: 'Mã CK' },
    { name: 'Email' },
    { name: 'Địa chỉ' },
  ];
  const onSort = (order) => {
    dispatch(
      setFilter({
        ...filter,
        sortBy: 'sharesNumber',
        order,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      }),
    );
  };
  const choseCheckBox = (item) => {
    var newListChose = [...listChose];
    if (findIndex(item, newListChose) < 0) {
      newListChose = [];
      newListChose.push(item);
    } else {
      newListChose.splice(newListChose.indexOf(item), 1);
    }
    dispatch(setChooseList(newListChose));
  };
  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          identityNumber: '',
          joinType: 'all',
          status: 'all',
          pageSize: PAGE_SIZE,
          pageNumber: 1,
        }),
      );
      dispatch(setChooseList([]));
      dispatch(getListAccount([]));
      dispatch(setTotal(0));
    };
  }, [dispatch]);
  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    // const {identityNumber, joinType, status, scale, type} = filter;
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };
  return (
    <Fragment>
      <Table header={theads} minWidth="1300px">
        {table.length > 0 ? (
          <>
            {table.map((item, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => choseCheckBox(item)}
                  className={findIndex(item, listChose) >= 0 ? 'active' : ''}
                >
                  <td className="center">
                    <Input.Checkbox
                      checked={findIndex(item, listChose) >= 0 ? true : false}
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td>{item.displayCode}</td>
                  <td>{item.name}</td>
                  <td>{item.identityNumber}</td>
                  <td>{item.identityDate}</td>
                  <td style={{ whiteSpace: 'normal', maxWidth: '200px' }}>
                    {item.identityAddress}
                  </td>
                  <td>{item.phone}</td>
                  <td>{transformNumberPrinter(item.sharesNumber)}</td>
                  <td>{item.stockCode}</td>
                  <td>{item.email}</td>
                  <td>{item.address}</td>
                  {/* <td style={{whiteSpace: 'normal', minWidth: '350px'}}>
                    {item.address}
                  </td> */}
                </tr>
              );
            })}
            {/* <tr>
                <td colSpan={100}>
                  <Pagination
                    handleChangePageSize={getPageSize}
                    current={filter.pageNumber}
                    total={total}
                    pageSize={filter.pageSize}
                    onChange={ getPage}
                  />
                </td>
              </tr>  */}
          </>
        ) : (
          <tr>
            <td colSpan={100} className="center">
              {' '}
              Chưa có dữ liệu{' '}
            </td>
          </tr>
        )}
      </Table>
      {table.length > 0 && (
        <Pagination
          handleChangePageSize={getPageSize}
          current={filter.pageNumber}
          total={total}
          pageSize={filter.pageSize}
          onChange={getPage}
        />
      )}
    </Fragment>
  );
};
export default TableComponent;
