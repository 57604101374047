import Radio from '@components/input/Radio';
import { setMethod } from '@duck/pages/admin/initializeReportReducer/actions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export default function Method({ setKindToSend, kindToSend }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onMethodChange = (val) => {
    dispatch(setMethod(val));
  };

  return (
    <div className="methods">
      <Radio
        checked={kindToSend === 6}
        text={t('Tờ trình')}
        onClick={() => {
          onMethodChange(0);
          setKindToSend(6);
        }}
      />
      <Radio
        checked={kindToSend === 4}
        text={t('Bầu dồn phiếu')}
        onClick={() => {
          onMethodChange(1);
          setKindToSend(4);
        }}
      />
    </div>
  );
}
