import * as constants from './constants';
import { callApi } from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';

// export const getListApi = (body) => {
//   const {identityNumber, joinType, status, pageSize, pageNumber} = body;
//   return (dispatch) =>
//     callApi(`${constants.URL}/${pageSize}/${pageNumber}`, 'POST', {
//       identityNumber,
//       joinType,
//       status,
//     }).then(function (res) {
//       dispatch(getListAccount(res.data.data.shareholders));
//       dispatch(getTotal(res.data.data.total));
//     });
// };

export const getSearchApi = (body) => {
  const { identityNumber, joinType, status, pageSize, pageNumber, isManual } =
    body;
  return (dispatch) =>
    callApi(`${constants.URL}/${pageSize}/${pageNumber}`, 'POST', {
      identityNumber,
      joinType,
      status,
      isManual,
    }).then(function (res) {
      dispatch(getListAccount(res.data.data.shareholders));
      dispatch(getTotal(res.data.data.total));
    });
};

export const setInvalidApi = (body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_SET_INVALID}`, 'POST', body)
      .then(function (res) {
        dispatch(setToggleModal(false));
        dispatch(setCreatingVote(false));
        dispatch(getSearchApi(filter));
        PNotify.success({
          title: 'Thành công',
          text: `Tạo phiếu thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        dispatch(setCreatingVote(false));
        // if (err) {
        //   if (err.response && err.response.data && err.response.data.message) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.data.message,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   } else if (err.response) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.statusText,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   }
        // }
      });
};

export const infoShareholderApi = (body, mainShareholderId) => {
  return (dispatch) =>
    callApi(`${constants.URL_VOTE_INFO}/${body}`, 'GET', null)
      .then(function (res) {
        dispatch(setListVotedResult(res.data.data));
        if (res.data.data.votes.length > 0) {
          // console.log(res.data.data.votes);
          const listAll = res.data.data.votes;
          let result1 = [];
          let result2 = [];
          let result3 = [];
          let result4 = [];
          let result6 = [];

          for (let i in listAll) {
            if (listAll[i].voter === mainShareholderId) {
              //  phân biệt theo người vote
              if (listAll[i].kind === 1) result1.push(listAll[i]);
              else if (listAll[i].kind === 2) result2.push(listAll[i]);
              else if (listAll[i].kind === 3) result3.push(listAll[i]);
              else if (listAll[i].kind === 4) result4.push(listAll[i]);
              else if (listAll[i].kind === 6) result6.push(listAll[i]);
            }
          }
          dispatch(setResult1(result1));
          dispatch(setResult2(result2));
          dispatch(setResult3(result3));
          dispatch(setResult4(result4));
          dispatch(setResult6(result6));
        } else {
          dispatch(setResult1([]));
          dispatch(setResult2([]));
          dispatch(setResult3([]));
          dispatch(setResult4([]));
          dispatch(setResult6([]));
        }
      })
      .catch(handleError);
};

export const getTopic1Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic1(res.data.topics));
    });
};
export const getTopic2Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic2(res.data.topics));
    });
};
export const getTopic3Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic3(res.data.topics));
    });
};
export const getTopic4Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic4(res.data.topics));
    });
};
export const getTopic6Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic6(res.data.topics));
    });
};
export const createVoteApi = (userId, votes, reload) => {
  return (dispatch) =>
    callApi(`${constants.URL_CREATE_VOTE}`, 'POST', {
      userId,
      votes,
    })
      .then(function (res) {
        dispatch(setCreatingVote(false));
        dispatch(setMarkConfirmModal(false));
        if (reload) {
          dispatch(infoShareholderApi(votes[0].shareholderId, userId));
        }
        PNotify.success({
          title: 'Thành công',
          text: `Tạo phiếu thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        dispatch(setCreatingVote(false));
      });
};
export const getListAuthority = (id) => {
  return (dispatch) =>
    callApi(`${constants.URL_GET_LIST_AUTHORITY}/${id}`, 'GET', null).then(
      function (res) {
        dispatch(setListAuthority(res.data.data));
      },
    );
};

export const createSelectVoteApi = (body) => {
  // body : { userId, votes: [{shareholderId,kind,topicId,selections,note,}]}
  // selections: [{_id, fixId,selectName, amount}]
  return (dispatch) =>
    callApi(`${constants.URL_CREATE_SELECT_VOTE}`, 'POST', body)
      .then(function (res) {
        dispatch(setCreatingVote(false));
        dispatch(setMarkConfirmModal(false));
        if (body.reload) {
          dispatch(
            infoShareholderApi(body.votes[0].shareholderId, body.userId),
          );
        }
        PNotify.success({
          title: 'Thành công',
          text: `Tạo phiếu thành công`,
          destroy: true,
          // delay: 2000,
        });
      })
      .catch(function (err) {
        dispatch(setCreatingVote(false));
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setVoter = (value) => {
  return {
    type: constants.SET_VOTER,
    value,
  };
};
export const setCreatingVote = (value) => {
  return {
    type: constants.SET_CREATING_VOTE,
    value,
  };
};
export const getTopic1 = (value) => {
  return {
    type: constants.GET_TOPIC1,
    value,
  };
};
export const getTotal = (value) => {
  return {
    type: constants.GET_TOTAL,
    value,
  };
};
export const getTopic2 = (value) => {
  return {
    type: constants.GET_TOPIC2,
    value,
  };
};
export const getTopic3 = (value) => {
  return {
    type: constants.GET_TOPIC3,
    value,
  };
};
export const getTopic4 = (value) => {
  return {
    type: constants.GET_TOPIC4,
    value,
  };
};
export const getTopic6 = (value) => {
  return {
    type: constants.GET_TOPIC6,
    value,
  };
};
export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};
export const setToggleConfirmModal = (value) => {
  return {
    type: constants.TOGGLE_CONFIRM_MODAL,
    value,
  };
};
export const setListVotedResult = (value) => {
  return {
    type: constants.SET_VOTED_RESULT,
    value,
  };
};
export const getResultCreateVote = (value) => {
  return {
    type: constants.GET_RESULT,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.GET_FILTER,
    value,
  };
};

export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};

export const setListAuthority = (value) => {
  return {
    type: constants.SET_LIST_AUTHORITY,
    value,
  };
};

export const setResult1 = (value) => {
  return {
    type: constants.SET_RESULT_KIND1,
    value,
  };
};
export const setResult2 = (value) => {
  return {
    type: constants.SET_RESULT_KIND2,
    value,
  };
};
export const setResult3 = (value) => {
  return {
    type: constants.SET_RESULT_KIND3,
    value,
  };
};
export const setResult4 = (value) => {
  return {
    type: constants.SET_RESULT_KIND4,
    value,
  };
};
export const setResult6 = (value) => {
  return {
    type: constants.SET_RESULT_KIND6,
    value,
  };
};
export const setAllNotVote = (value) => {
  return {
    type: constants.SET_ALL_NOT_VOTE,
    value,
  };
};
export const setMarkConfirmModal = (value) => {
  return {
    type: constants.SET_MARK_CONFIRM_MODAL,
    value,
  };
};
