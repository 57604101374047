import React, { useState, useEffect } from 'react';
import Input from 'components/bases/shared/input';
import Button from 'components/bases/shared/button';
import inputIcons from 'components/bases/shared/icons/input';
import { useForm } from 'react-hook-form';
import {
  SAuthorizationListPage,
  SSectionCustom,
} from 'styles/styled/admin/authorizationListPage/authorizationInformation';
// import { TableList } from './table.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFilter,
  getUserListApi,
  setChooseList,
} from '@duck/pages/admin/authorizationInfomationReducer/actions';
import { useTranslation } from 'react-i18next';
import { findIndex } from '@configs/findIndex';
import Table from '@components/table';
import Pagination from '@components/pagination';
import { transformNumberPrinter } from '@configs/transformNumber';
import EditModal from './modal/EditModal';
import LockModal from './modal/LockModal';
import SortModal from './modal/SortModal';
import DeleteModal from '@components/modal/ModalDelete';
import { URL_EDIT } from '@duck/pages/admin/authorizationInfomationReducer/constants';
import { callApi } from '@state-common/index';
import PNotify from 'pnotify/dist/es/PNotify';

const TabAccountSettings = () => {
  const { t } = useTranslation();
  const [modalType, setModalType] = useState('');
  const [toggleModal, setToggleModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const dispatch = useDispatch();
  const { handleSubmit, register, setValue, control } = useForm();
  const { filter, listChose, total, tableList } = useSelector(
    (state) => state.admin.authorizationInfoList,
  );

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      dispatch(
        setFilter({
          pageSize: 10,
          pageNumber: 1,
          type: 'all',
        }),
      );
      dispatch(setChooseList([]));
    }
  }, [firstTime, dispatch]);
  useEffect(() => {
    if (firstTime === false) {
      dispatch(setChooseList([]));
      dispatch(getUserListApi(filter));
    }
  }, [dispatch, firstTime, filter]);

  const theads = [
    { name: '#' },
    { name: 'Đại diện nhận ủy quyền' },
    { name: 'CMND/CCCD' },
    { name: 'Số điện thoại' },
    { name: 'Email' },
    { name: 'Chức vụ' },
    { name: 'Trạng thái' },
  ];

  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };
  const choseCheckBox = (item) => {
    var newListChose = [...listChose];
    if (findIndex(item, newListChose) < 0) {
      newListChose = [];
      newListChose.push(item);
    } else {
      newListChose.splice(newListChose.indexOf(item), 1);
    }
    dispatch(setChooseList(newListChose));
  };

  useEffect(() => {
    setValue('search', filter.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const onSearch = (values) => {
    dispatch(
      setFilter({
        ...filter,
        search: values.search,
        pageNumber: 1,
      }),
    );
  };

  const handleDelete = async () => {
    await callApi(`${URL_EDIT}/${listChose[0]._id}`, 'DELETE').then(function (
      res,
    ) {
      setToggleModal(false);
      setTimeout(() => {
        dispatch(getUserListApi(filter));
        dispatch(setChooseList([]));
      }, 500);
      PNotify.success({
        title: t('Thành công'),
        text: t('Xóa thành công'),
        destroy: true,
        delay: 2000,
      });
    });
  };
  return (
    <SAuthorizationListPage>
      <SSectionCustom>
        <div className="module-content">
          <div className="search-wrapper">
            <div className="search-item">
              <Input.Text
                register={register('search')}
                wrapperProps={{
                  label: t('Họ tên/CMND/CCCD/SĐT'),
                  icon: inputIcons.code,
                  height: '40px',
                }}
              />
            </div>
            <div className="search-item filter-search">
              <Button className="search" onClick={handleSubmit(onSearch)}>
                {t('Tìm kiếm')}{' '}
              </Button>
            </div>
          </div>
        </div>
      </SSectionCustom>
      <div className="table-container">
        <div className="table-tools">
          <div className="list-handle" style={{ minHeight: '40px' }}>
            {/* {tableList.length > 0 && (
              <Input.HandleButton
                type="select"
                select={areSelectedAll()}
                action={onSelectAll}
              />
            )} */}
            <Input.HandleButton
              type="add"
              action={() => {
                setToggleModal(true);
                setModalType('add');
              }}
            />
            <Input.HandleButton
              type="sort"
              action={() => {
                setIsOpen(true);
                setToggleModal(true);
                setModalType('sort');
              }}
            />
            {listChose.length === 1 && (
              <Input.HandleButton
                type="edit"
                action={() => {
                  setToggleModal(true);
                  setModalType('edit');
                }}
              />
            )}
            {listChose[0]?.isBlocked && (
              <Input.HandleButton
                type="un_lock"
                action={() => {
                  setIsOpen(true);
                  setModalType('unlock');
                }}
              />
            )}
            {listChose[0]?.isBlocked === false && (
              <Input.HandleButton
                type="lock_modal"
                action={() => {
                  setIsOpen(true);
                  setModalType('lock');
                }}
              />
            )}
            {listChose.length === 1 && (
              <Input.HandleButton
                type="delete"
                action={() => {
                  setToggleModal(true);
                  setModalType('delete');
                }}
              />
            )}
          </div>
          {/* <div className="search">
            <input
              className="search-input"
              placeholder={t('Họ tên, tài khoảnn')}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
            <svg
              onClick={() => {
                const filterNew = {
                  ...filter,
                  search: keyword,
                  pageNumber: 1,
                };
                dispatch(setFilter(filterNew));
              }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8067 12.86L11.54 10.6C12.2713 9.6683 12.6681 8.51777 12.6667 7.33333C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39977 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39977 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33333 12.6667C8.51777 12.6681 9.6683 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9187 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9187 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9187 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9187 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33333C3.33334 6.54221 3.56793 5.76885 4.00746 5.11105C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13316 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33333 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33333Z"
                fill="#00CB82"
              />
            </svg>
          </div> */}
        </div>

        <div className="table-wrapper">
          <Table
            header={theads}
            // minWidth="1200px"
            minWidth={filter.type === 'all' ? '1200px' : '900px'}
          >
            {tableList.length > 0 ? (
              filter.type === 'all' ? (
                <>
                  {tableList.map((item, index) => {
                    return (
                      <tr
                        onClick={() => choseCheckBox(item)}
                        className={
                          findIndex(item, listChose) >= 0 ? 'active' : ''
                        }
                      >
                        <td className="center">
                          <Input.Checkbox
                            checked={
                              findIndex(item, listChose) >= 0 ? true : false
                            }
                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.identityNumber}</td>
                        <td>{item.phone} </td>
                        <td>{item.email} </td>
                        <td>{item.position} </td>
                        <td
                          className={`${item.isBlocked ? 'fail' : 'success'}`}
                        >
                          {item.isBlocked === false
                            ? t('Đang hoạt động')
                            : t('Khóa')}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {tableList.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          {' '}
                          {filter.type === 'shareholder'
                            ? item.shareholder && item.shareholder.name
                            : item.name}{' '}
                        </td>
                        <td>
                          {filter.type === 'shareholder'
                            ? item.shareholder &&
                              item.shareholder.identityNumber
                            : item.identityNumber}
                        </td>
                        {/* <td>{transformNumberPrinter(item.sharesNumber)} </td> */}
                        <td>
                          {filter.type === 'shareholder'
                            ? item.shareholder &&
                              transformNumberPrinter(
                                item.shareholder.sharesNumber,
                              )
                            : transformNumberPrinter(item.sharesNumber)}
                        </td>
                        {filter.type === 'shareholder' ? (
                          <>
                            <td>{transformNumberPrinter(item.accept)}</td>
                            <td>{transformNumberPrinter(item.pending)}</td>
                            <td>
                              {item.shareholder
                                ? transformNumberPrinter(
                                    Number(item.shareholder.sharesNumber) -
                                      Number(item.accept) -
                                      Number(item.pending),
                                  )
                                : 0}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              {transformNumberPrinter(
                                item.sharesAuthorizedNumber,
                              )}
                            </td>
                            <td>
                              {transformNumberPrinter(
                                Number(item.sharesAuthorizedNumber) +
                                  Number(item.sharesNumber),
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </>
              )
            ) : (
              <tr>
                <td colSpan={100} className="center">
                  {' '}
                  {t('Chưa có dữ liệu')}{' '}
                </td>
              </tr>
            )}
          </Table>
          {tableList.length > 0 && (
            <Pagination
              handleChangePageSize={getPageSize}
              current={filter.pageNumber}
              total={total}
              pageSize={filter.pageSize}
              onChange={getPage}
            />
          )}
        </div>
      </div>
      {modalType === 'add' && (
        <EditModal
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="add"
        />
      )}
      {modalType === 'sort' && (
        <SortModal
          filter={filter}
          tableList={tableList}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="sort"
        />
      )}
      {modalType === 'edit' && (
        <EditModal
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="edit"
        />
      )}
      {modalType === 'lock' && (
        <LockModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalType="lock"
          title="Xác nhận nội dung"
        />
      )}
      {modalType === 'unlock' && (
        <LockModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalType="unlock"
          title="Xác nhận nội dung"
        />
      )}
      {modalType === 'delete' && (
        <DeleteModal
          title={t('Xóa nội dung')}
          onSubmit={handleDelete}
          isOpen={toggleModal}
          toggleModal={setToggleModal}
        />
      )}
    </SAuthorizationListPage>
  );
};
export default TabAccountSettings;
