import React, { useState } from 'react';
import { EditModal } from './modal';
import { useSelector, useDispatch } from 'react-redux';
import HandleButton from '@components/input/HandleButton';
import DeleteModal from '@components/modal/ModalDelete';
import { getDeleteApi } from '../../../../states/duck/pages/admin/shareholderListReducer/actions';
import { STableTools } from '@styled/admin/shareholderListPage';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import inputIcons from '@components/icons/input';
import { setLoading } from '@duck/pages/app/actions';
import {
  downloadPasswordListAPI,
  setDownloadPasswordList,
} from '../../../../states/duck/pages/shareholderRelationship/invitationManagementReducer/actions';
import XLSX from 'xlsx';
import { ExportSheet } from 'react-xlsx-sheet';
import ConfirmDownloadModal from './modal/confirmDownload';

const TableTools = ({ isInvitationDone }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listChose = useSelector(
    (state) => state.admin.shareholderList.listTopicChoose,
  );
  const downloadPasswordList = useSelector(
    (state) =>
      state.shareholderRelationship.invitationManagement.downloadPasswordList,
  );
  const passwordList = useSelector(
    (state) => state.shareholderRelationship.invitationManagement.passwordList,
  );
  const filter = useSelector((state) => state.admin.shareholderList.filter);
  const [modalType, setModalType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <STableTools>
        <div className="table-tools" style={{ minHeight: '28px' }}>
          <div className="left">
            {listChose.length === 1 ? (
              <HandleButton
                type="edit"
                tooltip={t('Sửa')}
                action={() => {
                  setModalType('edit');
                  setModalOpen(true);
                }}
              />
            ) : (
              ''
            )}
            {listChose.length && !isInvitationDone > 0 ? (
              <HandleButton
                type="delete"
                tooltip={t('Xóa tất cả')}
                action={() => {
                  setModalType('delete-all');
                  setModalOpen(true);
                }}
              />
            ) : (
              ''
            )}
          </div>
          {!isInvitationDone && (
            <div className="right">
              {!downloadPasswordList ? (
                <Button
                  className="white-button"
                  onClick={() => {
                    dispatch(downloadPasswordListAPI('all'));
                    dispatch(setLoading(true));
                  }}
                >
                  {inputIcons.plus}
                  {t('Xuất mật khẩu của Cổ đông')}
                </Button>
              ) : (
                <ExportSheet
                  dataType="Table-Node-Element"
                  fileName={`danhsach_matkhau`}
                  tableElement={document.querySelector('#print-content-table2')}
                  xlsx={XLSX}
                >
                  <button
                    className="btn-dif"
                    onClick={() => {
                      if (downloadPasswordList) {
                        dispatch(setDownloadPasswordList(false));
                      }
                    }}
                  >
                    {t('Tải xuống danh sách mật khẩu của Cổ đông')}
                  </button>
                </ExportSheet>
              )}
              <Button
                className="white-button"
                onClick={() => {
                  setModalType('lock-password');
                  setModalOpen(true);
                }}
              >
                {inputIcons.plus}
                {t('Khóa việc thay đổi mật khẩu')}
              </Button>
            </div>
          )}
        </div>
        {modalType === 'edit' && (
          <EditModal isOpen={modalOpen} setToggleModal={setModalOpen} />
        )}{' '}
        {modalType === 'delete-all' && (
          <DeleteModal
            // title="xoá các nội dung"
            isOpen={modalOpen}
            toggleModal={setModalOpen}
            content={t('Bạn có chắc chắn muốn xóa toàn bộ danh sách Cổ đông?')}
            onSubmit={() => {
              dispatch(getDeleteApi(filter));
            }}
          />
        )}
        {modalType === 'lock-password' && (
          <ConfirmDownloadModal
            isOpen={modalOpen}
            setToggleModal={setModalOpen}
          />
        )}
      </STableTools>
      <div
        className="address-list"
        id="print-content2"
        style={{ display: 'none' }}
      >
        <div className="table" id="print-password">
          <style
            dangerouslySetInnerHTML={
              // eslint-disable-next-line no-useless-escape
              {
                __html:
                  '\n       #print-password table{width: 100%;    border-spacing: 0; border-collapse: collapse;} table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto }   #print-password th,#print-password td, #print-password table{border: 1px solid #000;text-align: center;}#print-password {font-family: times}#print-password th,#print-password td{padding: 5px 10px;}#print-password th {white-space: no-wrap}\n',
              }
            }
          />
          <table id="print-content-table2">
            <thead>
              <tr>
                <th>Stt</th>
                <th>Mã Cổ đông</th>
                <th>Họ và tên</th>
                <th>Tài khoản đăng nhập</th>
                <th>mật khẩu</th>
              </tr>
            </thead>
            <tbody>
              {passwordList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text">&#8203;{item?.displayCode}</td>
                    <td className="text">{item.name}</td>
                    <td className="text">
                      &#8203;{item.identityNumber.toString()}
                    </td>
                    <td className="text">&#8203;{item.password}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default TableTools;
