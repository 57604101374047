import styled from 'styled-components';
import React, { useState } from 'react';
import { AutoScroll } from './components/AutoScroll';
import { getUserInfo } from '@pages/admin/systemPage/store';
import { useSelector } from 'react-redux';

export const ToolSection = ({
  isDetail,
  setDetail,
  isPlay,
  setPlay,
  setToggle,
  toggle,
}) => {
  // const [isAutoScroll, setAutoScroll] = useState(true);
  const userInfo = useSelector((state) => state.login.userInfo);
  return (
    <Styles>
      <div className="tool-cover" />
      <div
        className="tool-item"
        onClick={() => {
          setPlay((prev) => !prev);
        }}
      >
        <img src={isPlay ? '/images/pause.png' : '/images/play.png'} />
        <span>{isPlay ? 'Pause' : 'Play'}</span>
      </div>
      <div className="tool-item" onClick={() => setDetail((prev) => !prev)}>
        <img src="/images/detail.png" />
        <span>Detail</span>
      </div>
      <div
        className="tool-item"
        onClick={() => {
          const temp = document.querySelector('.web-app');
          // setAutoScroll(false);
          if (temp)
            temp.scrollIntoView({
              top: 0,
              behavior: 'smooth',
            });
        }}
      >
        <img src="/images/to-top.png" />
        <span>Back to Top</span>
      </div>
      {!!userInfo.isMainAdmin && (
        <div
          className="tool-item"
          onClick={() => {
            setToggle(true);
          }}
        >
          <EditSvg />
          <span>Setting</span>
        </div>
      )}

      {/* <AutoScroll isAutoScroll={isAutoScroll} /> */}
    </Styles>
  );
};

const Styles = styled.div`
  position: fixed;
  z-index: 1;
  top: 35%;
  right: 0;
  padding: 32px 9px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .tool-cover {
    clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);
    background: linear-gradient(to right, #03cb83, #03cb8300);
    position: absolute;
    inset: 0;
    ::before {
      content: '';
      clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%);
      position: absolute;
      background: white;
      inset: 1px;
    }
  }

  .tool-item {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    img {
      width: 32px;
      height: 32px;
    }
    span {
      display: none;
      padding: 3px 14.5px;
      background: white;
      position: absolute;
      right: 135%;
      top: 0;
      border: 1px solid #dfe9f5;
      border-radius: 2px;
    }
    :hover {
      span {
        display: block;
      }
    }
  }
`;
const EditSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M25.3196 2.68267L29.3196 6.68267L26.2703 9.73333L22.2703 5.73333L25.3196 2.68267ZM10.6689 21.3333H14.6689L24.3849 11.6173L20.3849 7.61733L10.6689 17.3333V21.3333Z"
      fill="#718098"
    />
    <path
      d="M25.3355 25.3333H10.8795C10.8449 25.3333 10.8089 25.3467 10.7742 25.3467C10.7302 25.3467 10.6862 25.3347 10.6409 25.3333H6.66886V6.66667H15.7982L18.4649 4H6.66886C5.1982 4 4.0022 5.19467 4.0022 6.66667V25.3333C4.0022 26.8053 5.1982 28 6.66886 28H25.3355C26.0428 28 26.7211 27.719 27.2211 27.219C27.7212 26.7189 28.0022 26.0406 28.0022 25.3333V13.776L25.3355 16.4427V25.3333Z"
      fill="#718098"
    />
  </svg>
);
