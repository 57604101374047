import {
  SModalCopyCoupon,
  SItemCopyCoupon,
  // SSelectionItem,
} from '@styled/checkCoupon/checkCouponArtisanPage';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StylesTable } from '@components/table';
import styled from 'styled-components';
import Input from '@components/input';
import Button from '@components/button';
import {
  createVoteApi,
  getListAuthority,
  infoShareholderApi,
  setInvalidApi,
  setVoter,
  setCreatingVote,
  setAllNotVote,
  setMarkConfirmModal,
  setToggleConfirmModal,
} from './../../../../states/duck/pages/checkCoupon/checkCouponArtisanReducer/actions';
import { useForm } from 'react-hook-form';
import PNotify from 'pnotify/dist/es/PNotify';
import CumulativeVoteItem from './cumulativeVoteItem';
import * as constants from '../../../../states/duck/pages/checkCoupon/checkCouponArtisanReducer/constants';
import { callApi } from '../../../../states/common';
import { useTranslation } from 'react-i18next';
import Modal from '@components/modal';
import ModalConfirm from './modal/modalConfirm';
import { useVoteConfig } from '@pages/resultPage/store';
import { resetVoteAgainApi } from '@duck/pages/admin/shareholderStatusReducer/actions';
import { formatNumber } from 'states/libs';

export const ModalCopy = () => {
  const { t, i18n } = useTranslation();
  const {
    listChose,
    topic1,
    topic2,
    topic3,
    topic4,
    topic6,
    listAuthority,
    filter,
    resultVote1,
    resultVote2,
    resultVote3,
    resultVote4,
    resultVote6,
    voter,
  } = useSelector((state) => state.checkCoupon.checkCouponArtisan);
  const dispatch = useDispatch();
  const { register, setValue, watch, control } = useForm();
  const [isInvalidVote, setIsInvalidVote] = useState(false);
  //no one voted: check for click Phieu khong hop le
  const [noOneVoted, setNoOneVoted] = useState(true);

  const checkInvalidEachKind123 = (kind, resultVotedKind) => {
    //check each kind (1,2,3) is all invalid
    if (kind.length !== resultVotedKind.length) return false;
    for (let i in resultVotedKind) {
      if (resultVotedKind[i].point !== 0) return false;
    }
    return true;
  };
  const checkInvalidEachKind4 = (kind, resultVotedKind) => {
    //check kind 4 is all invalid
    if (kind.length !== resultVotedKind.length) return false;
    for (let i in resultVotedKind) {
      if (!resultVotedKind[i].invalidVoted) return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(getListAuthority(listChose[0]._id));
  }, [dispatch, listChose]);
  useEffect(() => {
    if (
      checkInvalidEachKind123(topic1, resultVote1) &&
      checkInvalidEachKind123(topic2, resultVote2) &&
      checkInvalidEachKind123(topic3, resultVote3) &&
      checkInvalidEachKind4(topic4, resultVote4) &&
      checkInvalidEachKind123(topic6, resultVote6)
    ) {
      setIsInvalidVote(true);
    } else setIsInvalidVote(false);
  }, [resultVote1, resultVote2, resultVote3, resultVote4, resultVote6]);
  //get result of all shareholder
  useEffect(() => {
    const init = async () => {
      if (listChose && listChose[0]) {
        let listAuthorityMirror = [...listAuthority];
        const listAuthorityId = listAuthorityMirror.map(
          (item) => item.shareholder._id,
        );
        const listAuthorityIdSharesNumber = listAuthorityMirror.map((item) => ({
          id: item.shareholder._id,
          sharesNumber: item.sharesAuthorizedNumber,
        }));
        if (listChose[0].sharesNumber !== 0) {
          listAuthorityId.push(listChose[0]._id);
          listAuthorityIdSharesNumber.push({
            id: listChose[0]._id,
            sharesNumber: listChose[0].sharesNumber,
          });
        }

        let kind4 = {};
        let kind1 = [...listAuthorityId];
        let kind2 = [...listAuthorityId];
        let kind3 = [...listAuthorityId];
        let kind6 = [...listAuthorityId];
        if (topic4.length > 0) {
          for (let i = 0; i < topic4.length; i++) {
            kind4[topic4[i]._id] = [...listAuthorityIdSharesNumber];
          }
        }
        for (let i = 0; i < listAuthorityId.length; i++) {
          await callApi(
            `${constants.URL_VOTE_INFO}/${listAuthorityId[i]}`,
            'GET',
            null,
          ).then((res) => {
            const data = res.data.data;
            const id = data.shareholder._id;

            const vote = data.votes.map((item) => {
              if (item.kind !== 4 && item.voter === listChose[0]._id) {
                return item.kind;
              } else if (
                item.kind === 4 &&
                item.voter === listChose[0]._id &&
                item.topic_id
              ) {
                return item.topic_id._id;
              }
            });
            if (vote.length > 0 && noOneVoted) {
              setNoOneVoted(false);
            }

            if (vote.indexOf(1) >= 0) {
              let index = kind1.indexOf(id);
              if (index >= 0) kind1.splice(index, 1);
            }
            if (vote.indexOf(2) >= 0) {
              let index = kind2.indexOf(id);
              if (index >= 0) kind2.splice(index, 1);
            }
            if (vote.indexOf(3) >= 0) {
              let index = kind3.indexOf(id);
              if (index >= 0) kind3.splice(index, 1);
            }
            if (vote.indexOf(6) >= 0) {
              let index = kind6.indexOf(id);
              if (index >= 0) kind6.splice(index, 1);
            }
            for (let i in kind4) {
              if (vote.indexOf(i) >= 0) {
                let index = kind4[i].findIndex((k) => k.id == id);
                if (index >= 0) kind4[i].splice(index, 1);
              }
            }
          });
        }
        dispatch(
          setAllNotVote({
            kind4,
            kind1,
            kind2,
            kind3,
            kind6,
          }),
        );
      }
    };
    init();
  }, [listAuthority, topic4, listChose, dispatch]);

  const voterOption = useMemo(() => {
    //neu co dong ngoai he thong: khong them vao voterOption
    let data =
      listChose[0].sharesNumber === 0
        ? []
        : [
            {
              label: listChose[0].name,
              value: -1,
            },
          ];
    for (let i in listAuthority) {
      data.push({
        label: listAuthority[i].shareholder.name,
        value: Number(i),
      });
    }

    if (
      !(listAuthority.length === 1 && listChose[0].sharesNumber === 0) &&
      listAuthority.length > 0
    ) {
      //check neu co dong A uy quyen cho co dong B ngoai he thong, chi hien thi option cua co dong A
      data.unshift({
        label: i18n.language === 'vi' ? 'Tất cả' : 'All',
        value: -2,
      });
    }

    return data;
  }, [listAuthority, listChose]);

  useEffect(() => {
    //co dong ngoai he thong nhan uy quyen tu 1 co dong
    if (listChose[0].sharesNumber === 0 && listAuthority.length === 1) {
      dispatch(
        infoShareholderApi(listAuthority[0].shareholder._id, listChose[0]._id),
      );
      dispatch(
        setVoter({
          voterId: listAuthority[0].shareholder._id,
          voterShares: listAuthority[0].shareholder.sharesNumber,
        }),
      );
      setValue('name', listAuthority[0].shareholder.name);
      setValue('idCard', listAuthority[0].shareholder.identityNumber);
      setValue(
        'shares',
        formatNumber(listAuthority[0].shareholder.sharesNumber),
      );
      setValue('nameSelect', {
        label: listAuthority[0].shareholder.name,
        value: 0,
      });
    }
    //truoong hop khong ai uy quyen
    else if (listAuthority.length === 0) {
      dispatch(infoShareholderApi(listChose[0]._id, listChose[0]._id));
      dispatch(
        setVoter({
          voterId: listChose[0]._id,
          voterShares: listChose[0].sharesNumber,
        }),
      );
      setValue('name', listChose[0].name);
      setValue('idCard', listChose[0].identityNumber);
      setValue('shares', formatNumber(listChose[0].sharesNumber));
      setValue('nameSelect', {
        label: listChose[0].name,
        value: -1,
      });
    }
    //truong hop nhieu nguoi quy quyen // nhieu nguoiw vote
    else {
      if (voterOption[0]) setValue('nameSelect', voterOption[0]);
    }
  }, [listChose, dispatch, setValue, voterOption, listAuthority]);
  useEffect(() => {
    if (watch('nameSelect')) {
      if (watch('nameSelect').value === -1) {
        setValue('idCard', listChose[0].identityNumber);
        setValue('shares', formatNumber(listChose[0].sharesNumber));
        dispatch(
          setVoter({
            voterId: listChose[0]._id,
            voterShares: listChose[0].sharesNumber,
          }),
        );

        dispatch(infoShareholderApi(listChose[0]._id, listChose[0]._id));
      } else if (watch('nameSelect').value === -2) {
        if (isInvalidVote) setIsInvalidVote(false);
        dispatch(
          setVoter({
            voterId: 'all',
            voterShares: 0,
          }),
        );
      } else {
        setValue(
          'shares',
          formatNumber(
            listAuthority[watch('nameSelect').value].sharesAuthorizedNumber,
          ),
        );
        setValue(
          'idCard',
          listAuthority[watch('nameSelect').value].shareholder
            ? listAuthority[watch('nameSelect').value].shareholder
                .identityNumber
            : '',
        );
        dispatch(
          setVoter({
            voterId: listAuthority[watch('nameSelect').value].shareholder
              ? listAuthority[watch('nameSelect').value].shareholder._id
              : '',
            voterShares:
              listAuthority[watch('nameSelect').value].sharesAuthorizedNumber,
          }),
        );
        dispatch(
          infoShareholderApi(
            listAuthority[watch('nameSelect').value].shareholder._id,
            listChose[0]._id,
          ),
        );
      }
    }
  }, [watch('nameSelect')?.value]);

  return (
    <SModalCopyCoupon>
      <div className="info">
        <div className="info-item">
          {listAuthority.length === 0 ? (
            <Input.Text
              register={register('name')}
              inputProps={{ disabled: true }}
              wrapperProps={{
                label: t('Họ tên CĐ/Người được ủy quyền'),
                //   required: true,
              }}
            />
          ) : (
            <Input.Select
              name="nameSelect"
              control={control}
              selectProps={{
                placeholder: t('Cổ đông/ Người ủy quyền'),
                options: voterOption,
              }}
            />
          )}
        </div>
        {watch('nameSelect') && watch('nameSelect').value !== -2 && (
          <>
            <div className="info-item">
              <Input.Text
                register={register('idCard')}
                inputProps={{ disabled: true }}
                wrapperProps={{
                  label: t('CMND/CCCD'),
                }}
              />
            </div>
            <div className="info-item">
              <Input.Text
                register={register('shares')}
                inputProps={{ disabled: true }}
                wrapperProps={{
                  label: t('CP tham dự'),
                }}
              />
            </div>
          </>
        )}
        <div className="info-item ">
          <div
            className={
              voter.voterId === 'all'
                ? 'invalid-btn'
                : isInvalidVote
                ? 'active invalid-btn'
                : 'invalid-btn'
            }
            onClick={() => {
              //check nếu phiếu đã sao chép kết quả thì không cho chọn phiếu không hợp lệ
              if (
                !(
                  resultVote1.length > 0 ||
                  resultVote2.length > 0 ||
                  resultVote3.length > 0 ||
                  resultVote4.length > 0 ||
                  resultVote6.length > 0
                ) &&
                watch('nameSelect').value !== -2
              ) {
                //userId, shareholderIds - body for invalid vote
                let body = {
                  userId: listChose[0]._id,
                };
                if (watch('nameSelect').value === -1) {
                  body = {
                    ...body,
                    shareholderIds:
                      listChose[0].sharesNumber > 0
                        ? [listChose[0]._id]
                        : listChose[0]._id,
                  };
                } else {
                  let shareholderIds = [];
                  if (listAuthority[watch('nameSelect').value].shareholder)
                    shareholderIds.push(
                      listAuthority[watch('nameSelect').value].shareholder._id,
                    );
                  body = {
                    ...body,
                    shareholderIds,
                  };
                }
                dispatch(setInvalidApi(body, filter));
              } else if (noOneVoted && watch('nameSelect').value === -2) {
                let body = {
                  userId: listChose[0]._id,
                };
                const shareholderIds = listAuthority.map(
                  (item) => item.shareholder._id,
                );
                if (listChose[0].sharesNumber > 0) {
                  shareholderIds.push(listChose[0]._id);
                }
                body = {
                  ...body,
                  shareholderIds,
                };
                dispatch(setInvalidApi(body, filter));
              } else {
                PNotify.error({
                  title: t('Lỗi'),
                  text: t(
                    'Không thể đánh dấu Không hợp lệ cho phiếu bầu đã được sao chép',
                  ),
                  destroy: true,
                  delay: 2000,
                });
              }
            }}
          >
            {t('Phiếu không hợp lệ')}
          </div>
        </div>
      </div>
      <div className="copy_vote-container">
        {topic1 && topic1.length > 0 ? (
          <CopyItem
            topic={topic1}
            kind={1}
            shareholder={listAuthority[watch('nameSelect')?.value]}
          />
        ) : (
          ''
        )}
        {topic2 && topic2.length > 0 ? (
          <CopyItem
            topic={topic2}
            kind={2}
            shareholder={listAuthority[watch('nameSelect')?.value]}
          />
        ) : (
          ''
        )}
        {topic6 && topic6.length > 0 ? (
          <CopyItem
            topic={topic6}
            kind={6}
            shareholder={listAuthority[watch('nameSelect')?.value]}
          />
        ) : (
          ''
        )}
        {topic4 && topic4.length > 0 ? (
          <CopyItem
            topic={topic4}
            kind={4}
            shareholder={listAuthority[watch('nameSelect')?.value]}
          />
        ) : (
          ''
        )}
        {topic3 && topic3.length > 0 ? (
          <CopyItem
            topic={topic3}
            kind={3}
            shareholder={listAuthority[watch('nameSelect')?.value]}
          />
        ) : (
          ''
        )}
      </div>
    </SModalCopyCoupon>
  );
};

const CopyItem = ({ kind, topic, shareholder }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getVoteLabel } = useVoteConfig();
  const theads = [
    { name: '#' },
    { name: t('Nội dung') },
    { name: getVoteLabel(1) },
    { name: getVoteLabel(2) },
    { name: getVoteLabel(3) },
  ];
  const [result, setResult] = useState([]); // vote for kind 1 2 3
  const [isVoted, setIsVoted] = useState(false);

  const [listVotedCheck, setListVotedCheck] = useState([]);
  const [votedRes, setVotedRes] = useState([]);
  const {
    voter,
    resultVote1,
    resultVote2,
    resultVote3,
    resultVote4,
    resultVote6,
    listChose,
    allNotVote,
    toggleConfirmModal,
    markModalConfirm,
  } = useSelector((state) => state.checkCoupon.checkCouponArtisan);
  const allVoted = useMemo(() => {
    if (voter.voterId === 'all' && allNotVote[`kind${kind}`].length === 0) {
      return true;
    }
    return false;
  }, [voter, allNotVote, kind]);
  useEffect(() => {
    if (
      voter.voterId !== 'all' &&
      ((kind === 1 && resultVote1 && resultVote1.length > 0) ||
        (kind === 2 && resultVote2.length > 0) ||
        (kind === 3 && resultVote3.length > 0) ||
        (kind === 4 && resultVote4.length > 0) ||
        (kind === 6 && resultVote6.length > 0))
    ) {
      setIsVoted(true);
    } else setIsVoted(false);

    if (kind !== 4)
      switch (kind) {
        case 1:
          setVotedRes(voter.voterId !== 'all' ? resultVote1 : []);
          break;
        case 2:
          setVotedRes(voter.voterId !== 'all' ? resultVote2 : []);
          break;
        case 3:
          setVotedRes(voter.voterId !== 'all' ? resultVote3 : []);
          break;
        case 6:
          setVotedRes(voter.voterId !== 'all' ? resultVote6 : []);
          break;
        default:
          setVotedRes([]);
      }
  }, [resultVote1, resultVote2, resultVote3, kind, voter.voterId, resultVote6]);

  useEffect(() => {
    // if (voter.voterId === 'all') {
    //   setIsVoted(false);
    // }
    // set result default: all agree point 1
    const temp = topic.map((t) => ({
      shareholderId: voter.voterId,
      kind: kind,
      topicId: t._id,
      point: 1,
      note: '',
    }));
    setResult(temp);
  }, [voter.voterId]);

  useEffect(() => {
    let temp = [];
    if (kind !== 4) {
      let haveRes = false;
      topic.map((topicItem, index) => {
        haveRes = false;
        for (let i in votedRes) {
          if (
            topicItem &&
            votedRes[i] &&
            votedRes[i].topic_id &&
            votedRes[i].topic_id._id === topicItem._id
          ) {
            temp.push(votedRes[i].point);
            haveRes = true;
          }
        }
        if (!haveRes) {
          temp.push(0);
        }
      });
    }
    setListVotedCheck(temp);
  }, [topic, votedRes]);
  const findIndexObjectByIdAndPoint = (array, topicId, votePoint) => {
    for (let i in array) {
      if (array[i].topicId === topicId && votePoint === array[i].point)
        return i;
    }
    return -1;
  };
  const checkVote = (topicItem, votePoint) => {
    if (topicItem.unableShareholder.indexOf(voter.voterId) >= 0) {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Cổ đông bị chặn biểu quyết cho nội dung này'),
        destroy: true,
        delay: 2000,
      });
    } else {
      let resultTemp = [...result];
      let index = findIndexObjectByIdAndPoint(result, topicItem._id, votePoint);
      //check is this select(width id topic and point) (can select multiple point) is exist
      const tempItem = {
        shareholderId: voter.voterId,
        kind: kind,
        topicId: topicItem._id,
        point: votePoint,
        note: '',
      };
      if (index > -1) {
        resultTemp.splice(index, 1);
      } else resultTemp.push(tempItem);
      setResult(resultTemp);
    }
  };

  const isAllCheck = (point) => {
    for (let i in topic) {
      if (findIndexObjectByIdAndPoint(result, topic[i]._id, point) === -1)
        return false;
    }
    return true;
  };

  const checkAll = (point) => {
    let resultTemp = [...result];
    if (isAllCheck(point)) {
      for (let i in topic) {
        let index = findIndexObjectByIdAndPoint(
          resultTemp,
          topic[i]._id,
          point,
        );
        resultTemp.splice(index, 1);
      }
    } else {
      for (let i in topic) {
        let index = findIndexObjectByIdAndPoint(
          resultTemp,
          topic[i]._id,
          point,
        );
        if (index < 0) {
          resultTemp.push({
            shareholderId: voter.voterId,
            kind: kind,
            topicId: topic[i]._id,
            point,
            note: '',
          });
        }
      }
    }
    setResult(resultTemp);
  };

  const dispatch = useDispatch();
  const checkPoint = (id, point) => {
    for (let i in result) {
      if (result[i].topicId === id && result[i].point === point) return true;
    }
    return false;
  };

  const showError = () => {
    if (listChose[0].invalidVoted) {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Không thể sao chép phiếu không hợp lệ'),
        destroy: true,
        delay: 2000,
      });
    } else
      PNotify.error({
        title: t('Lỗi'),
        text: t('Cổ đông không thể thay đổi biểu quyết đã gửi'),
        destroy: true,
        delay: 2000,
      });
  };

  const onSendVote = async () => {
    dispatch(setCreatingVote(true));
    let message = '';
    if (isVoted) {
      if (kind === 1)
        message = t(
          'Cổ đông đã hoàn thành việc biểu quyết thông qua thủ tục khai mạc Đại hội',
        );
      else if (kind === 2)
        message = t('Cổ đông đã hoàn thành biểu quyết báo cáo/tờ trình');
      else if (kind === 3)
        message = t(
          'Cổ đông đã hoàn thành biểu quyết thông qua biên bản/nghị quyết Đại hội',
        );
      else if (kind === 4) message = t('Cổ đông đã bầu cử');
      else if (kind === 6) message = t('Cổ đông đã bầu cử');
      PNotify.error({
        title: t('Lỗi'),
        text: message,
        destroy: true,
        delay: 2000,
      });
    } else {
      const tmpResult = [...result];
      for (let i = 0; i < tmpResult.length; i++) {
        for (let j = i + 1; j < tmpResult.length; j++) {
          if (tmpResult[i].topicId === tmpResult[j].topicId) {
            const tmpItem = {
              ...tmpResult[i],
              point: 0,
            };
            tmpResult.splice(i, 1, tmpItem);
            tmpResult.splice(j, 1);
            j--;
          }
        }
      }
      let collectShareholderVote = [...allNotVote[`kind${kind}`]];
      if (voter.voterId === 'all') {
        let ressultConfig = [];

        for (let i = 0; i < collectShareholderVote.length; i++) {
          let resultClone = [...tmpResult].map((item) => ({
            ...item,
            shareholderId: collectShareholderVote[i],
          }));
          ressultConfig = ressultConfig.concat(resultClone);
        }
        dispatch(setCreatingVote(true));

        //delete unableShareholder
        if (kind === 2 || kind === 6) {
          for (let i in topic) {
            if (
              topic[i].unableShareholder &&
              topic[i].unableShareholder.length > 0
            ) {
              for (let j = 0; j < ressultConfig.length; j++) {
                if (
                  ressultConfig[j].topicId === topic[i]._id &&
                  topic[i].unableShareholder.includes(
                    ressultConfig[j].shareholderId,
                  )
                ) {
                  ressultConfig.splice(j, 1);
                  j--;
                }
              }
            }
          }
        }

        dispatch(
          createVoteApi(listChose[0]._id, ressultConfig, {
            reload: voter.voterId === 'all' ? false : true,
          }),
        );
        dispatch(
          setAllNotVote({
            ...allNotVote,
            [`kind${kind}`]: [],
          }),
        );
      } else {
        //delete unableShareholder
        if (kind === 2 || kind === 6) {
          for (let i in topic) {
            if (
              topic[i].unableShareholder &&
              topic[i].unableShareholder.length > 0
            ) {
              for (let j = 0; j < tmpResult.length; j++) {
                if (
                  tmpResult[j].topicId === topic[i]._id &&
                  topic[i].unableShareholder.includes(
                    tmpResult[j].shareholderId,
                  )
                ) {
                  tmpResult.splice(j, 1);
                  j--;
                }
              }
            }
          }
        }
        dispatch(setCreatingVote(true));
        dispatch(
          createVoteApi(listChose[0]._id, tmpResult, {
            reload: voter.voterId === 'all' ? false : true,
          }),
        );
        let index = collectShareholderVote.indexOf(voter.voterId);
        collectShareholderVote.splice(index, 1);
        dispatch(
          setAllNotVote({
            ...allNotVote,
            [`kind${kind}`]: collectShareholderVote,
          }),
        );
      }
    }
  };

  const closeModal = (value) => {
    dispatch(setToggleConfirmModal(false));
  };
  const resetVoteAgent = async () => {
    await dispatch(
      resetVoteAgainApi(
        {
          shareholderIds: !shareholder
            ? [listChose[0]._id]
            : [shareholder?.shareholder?._id],
        },
        kind,
        listChose[0]._id,
      ),
    );
  };

  return (
    <SItemCopyCoupon>
      <div className="title">
        {kind === 1
          ? `1. ${t('Biểu quyết thông qua thủ tục khai mạc Đại ')}`
          : kind === 2
          ? `2. ${t('Biểu quyết Báo cáo/Tờ trình')}`
          : kind === 4
          ? `3.2 ${t('Bầu cử (Bầu dồn phiếu)')}`
          : kind === 6
          ? `3.1 ${t('Biểu quyết thông qua nội dung bầu cử')}`
          : `4. ${t('Thông qua Biên bản - Nghị quyết Đại hội')}`}
      </div>
      <div className="content">
        {kind === 4 ? (
          <>
            {topic.map((item, index) => {
              // if (item.selections.length > 0)
              return (
                <CumulativeVoteItem
                  key={index}
                  topic={item}
                  resetVoteAgent={resetVoteAgent}
                />
              );
              // else return null;
            })}
          </>
        ) : (
          <StylesTable>
            <div className="table-container">
              <CustomeStylesTable>
                <table style={{ minWidth: '850px' }}>
                  <thead>
                    <tr className="bold">
                      {theads.map((theadItem, index) => {
                        return (
                          theadItem.name && (
                            <th key={index}>
                              {theadItem.name}
                              {index > 1 && (
                                <Input.Checkbox
                                  checked={
                                    (isVoted && voter.voterId !== 'all') ||
                                    allVoted
                                      ? false
                                      : isAllCheck(index - 1)
                                  }
                                  onChange={() => {
                                    if (
                                      !(
                                        (isVoted && voter.voterId !== 'all') ||
                                        allVoted
                                      )
                                    )
                                      checkAll(index - 1);
                                  }}
                                />
                              )}
                            </th>
                          )
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {topic.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="left">
                            {language === 'vi' ? item.name : item.name_2}
                          </td>
                          {!(
                            voter.voterId !== 'all' &&
                            (kind === 2 || kind === 6) &&
                            item.unableShareholder &&
                            item.unableShareholder.includes(voter.voterId)
                          ) && (
                            <>
                              {getVoteLabel(1) && (
                                <td>
                                  <Input.Checkbox
                                    checked={
                                      allVoted
                                        ? false
                                        : votedRes.length > 0
                                        ? listVotedCheck[index] === 1
                                          ? true
                                          : false
                                        : Number(checkPoint(item._id, 1))
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      if (
                                        votedRes.length > 0 ||
                                        listChose[0].invalidVoted
                                      ) {
                                        showError();
                                      } else if (!allVoted) {
                                        checkVote(item, 1);
                                      }
                                    }}
                                  />
                                </td>
                              )}
                              {getVoteLabel(2) && (
                                <td>
                                  <Input.Checkbox
                                    checked={
                                      allVoted
                                        ? false
                                        : votedRes.length > 0
                                        ? listVotedCheck[index] === 2
                                          ? true
                                          : false
                                        : Number(checkPoint(item._id, 2))
                                        ? true
                                        : false
                                      // Number(checkPoint(item._id)) === 2 ? true : false
                                    }
                                    onChange={() => {
                                      // checkVote(item, 2);
                                      if (
                                        votedRes.length > 0 ||
                                        listChose[0].invalidVoted
                                      ) {
                                        showError();
                                      } else if (!allVoted) {
                                        checkVote(item, 2);
                                      }
                                    }}
                                  />
                                </td>
                              )}
                              {getVoteLabel(3) && (
                                <td>
                                  <Input.Checkbox
                                    checked={
                                      allVoted
                                        ? false
                                        : votedRes.length > 0
                                        ? listVotedCheck[index] === 3
                                          ? true
                                          : false
                                        : Number(checkPoint(item._id, 3))
                                        ? true
                                        : false
                                      // Number(checkPoint(item._id)) === 3 ? true : false
                                    }
                                    onChange={() => {
                                      // checkVote(item, 3);
                                      if (
                                        votedRes.length > 0 ||
                                        listChose[0].invalidVoted
                                      ) {
                                        showError();
                                      } else if (!allVoted) {
                                        checkVote(item, 3);
                                      }
                                    }}
                                  />
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan={100} className="center">
                        <Button
                          className={
                            listChose[0].invalidVoted
                              ? 'disabled-btn'
                              : (isVoted && voter.voterId !== 'all') || allVoted
                              ? 'disabled-btn'
                              : ''
                          }
                          // onClick={onSendVote}
                          onClick={() => {
                            dispatch(setToggleConfirmModal(true));
                            dispatch(setMarkConfirmModal(kind + ''));
                          }}
                        >
                          {(isVoted && voter.voterId !== 'all') || allVoted
                            ? t('Đã biểu quyết')
                            : t('Hoàn thành BKP')}
                        </Button>
                        {isVoted && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            onClick={resetVoteAgent}
                          >
                            Đặt lại kết quả
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CustomeStylesTable>
            </div>
          </StylesTable>
        )}
      </div>
      {markModalConfirm === kind + '' && (
        <Modal
          isOpen={toggleConfirmModal}
          toggleModal={closeModal}
          title={t('Xác nhận gửi biểu quyết')}
          overwriteModalStyle={{
            maxWidth: '500px',
            zIndex: '200',
          }}
        >
          {toggleConfirmModal && (
            <ModalConfirm
              kind={kind}
              onConfirm={onSendVote}
              onRefuse={() => dispatch(setToggleConfirmModal(false))}
              // agree={result.filter((item) => item.point === 1).length}
              // disagree={result.filter((item) => item.point === 2).length}
              // noIdea={result.filter((item) => item.point === 3).length}
              result={getValidVotes(result, topic)}
              totalTopic={topic.length}
            />
          )}
        </Modal>
      )}
    </SItemCopyCoupon>
  );
};

const getValidVotes = (resultVotes, topic) => {
  return resultVotes.filter(
    (r) =>
      !topic.find(
        (t) =>
          r.topicId == t._id &&
          t.unableShareholder &&
          t.unableShareholder.includes(r.shareholderId),
      ),
  );
};

export const CustomeStylesTable = styled.div`
  /* user-select: none; */

  table {
    thead tr th {
      text-align: center;
    }
    thead tr th:nth-child(1) {
      text-align: left;
    }
    thead tr th:nth-child(2) {
      text-align: left;
    }
    tbody {
      tr {
        td {
        }
        td:first-child {
          text-align: center;
          padding: 10px 5px;
        }
      }
    }
  }
  .pagination-container {
    width: 100%;
    color: #718098;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid white;
    background: #ffffff80;
    padding: 5px;
  }
`;
