import Pagination from '@components/pagination';
import Table from '@components/table';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE } from '../../../../states/common/constants';

import { transformNumberPrinter } from '../../../bases/configs';
import {
  getListAccount,
  getUserApi,
  setFilter,
  setTotal,
} from '@duck/pages/admin/shareholderListRepresent/actions';
import {
  getFileExtension,
  isImage,
} from '../authorizationListPage/authorizationList/table';

const TableContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const table = useSelector(
    (state) => state.admin.shareholderRepresentative.tableList,
  );
  const total = useSelector(
    (state) => state.admin.shareholderRepresentative.total,
  );
  const filter = useSelector(
    (state) => state.admin.shareholderRepresentative.filter,
  );
  const theads = [
    { name: 'STT' },
    { name: 'Mã CĐ' },
    { name: 'Tên tổ chức' },
    { name: 'CMND/CCCD' },
    {
      name: 'Số lượng CP',
    },
    { name: 'Họ tên đại diện tổ chức' },
    { name: 'CMND/CCCD' },
    { name: 'Số điện thoại' },
    { name: 'File đính kèm' },
  ];
  useEffect(() => {
    dispatch(getUserApi(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          identityNumber: '',
          joinType: 'all',
          status: 'all',
          pageSize: PAGE_SIZE,
          pageNumber: 1,
        }),
      );
      dispatch(getListAccount([]));
      dispatch(setTotal(0));
    };
  }, [dispatch]);
  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };
  return (
    <div style={{ marginTop: '20px' }}>
      <Table header={theads} minWidth="1250px">
        {table && table?.length > 0 ? (
          <>
            {table.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.displayCode}</td>
                  <td>{item?.name}</td>
                  <td>{item?.identityNumber}</td>
                  <td>{transformNumberPrinter(item?.sharesNumber)}</td>
                  <td>{item?.representativeShareholder?.name}</td>
                  <td>{item?.representativeShareholder?.identityNumber}</td>
                  <td>{item?.representativeShareholder?.phone}</td>
                  <td>
                    <div
                      className="files-list"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 6,
                      }}
                    >
                      {(item?.representativeShareholder?.files || []).map(
                        (el, i) => (
                          <a
                            className="image-link"
                            rel="noopener noreferrer"
                            href={`${
                              process.env.REACT_APP_URL_RESULT + '/' + el
                            }`}
                            target="__blank"
                            key={i}
                            style={{
                              color: '#2f80ed',
                              textDecoration: 'underline',
                            }}
                          >
                            {isImage(el)
                              ? `anhdinhkem${getFileExtension(el)}`
                              : `filedinhkem${getFileExtension(el)}`}
                          </a>
                        ),
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td colSpan={100} className="center">
              {' '}
              {t('Chưa có dữ liệu')}{' '}
            </td>
          </tr>
        )}
      </Table>
      {table?.length > 0 && (
        <Pagination
          handleChangePageSize={(size) => {
            getPageSize(size);
          }}
          current={filter?.pageNumber}
          total={total || 0}
          pageSize={filter?.pageSize}
          onChange={(page) => {
            getPage(page);
          }}
        />
      )}
    </div>
  );
};
export default TableContent;
