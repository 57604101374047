import React, { useEffect, Fragment, useState } from 'react';
import {
  getSearchApi,
  getListAccount,
} from '../../../../states/duck/pages/checkin/checkInHandReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { transformNumberPrinter } from './../../../bases/configs';
import { PAGE_SIZE } from '../../../../states/common/constants';
import Table from '@components/table';
import ButtonCustom from '@components/buttonCustom';
import { STable } from '@styled/checkIn/checkInHandPage';
import Pagination from '@components/pagination';
import { useTranslation } from 'react-i18next';
import ConfirmModal from './modal/confirmModal';
import QrModal from './modal/qrModal';

const TableCheckInHand = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const table = useSelector((state) => state.checkin.checkInHand.tableList);
  const { filter, total } = useSelector((state) => state.checkin.checkInHand);
  const info = useSelector((state) => state.admin.configSystem.info);
  const [modalType, setModalType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [sort, setSort] = useState('');
  useEffect(() => {
    if (!firstTime) {
      dispatch(
        getSearchApi({
          identityNumber: filter.idCard,
          joinType: 'all',
          status: filter.status,
          pageSize: PAGE_SIZE,
          pageNumber: filter.pageNumber,
          isCheckedIn: filter.isCheckedIn,
          order: !!sort ? sort : '',
          sortBy: !!sort ? 'sharesNumber' : '',
        }),
      );
    } else {
      dispatch(
        getSearchApi({
          identityNumber: filter.idCard,
          joinType: 'all',
          status: filter.status,
          pageSize: PAGE_SIZE,
          pageNumber: 1,
          isCheckedIn: filter.isCheckedIn,
          order: !!sort ? sort : '',
          sortBy: !!sort ? 'sharesNumber' : '',
        }),
      );
      setFirstTime(false);
    }
  }, [dispatch, sort]);

  const getPage = (value) => {
    dispatch(
      getSearchApi({
        ...filter,
        pageNumber: value,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(getListAccount([]));
    };
  }, [dispatch]);

  const checkin = (item, type) => {
    setCurrentItem(item);

    setModalType(type);
    setModalOpen(true);
  };
  const sortUp = () => {
    setSort('asc');
  };
  const sortDown = () => {
    setSort('desc');
  };
  const theads = [
    { name: 'Mã Cổ đông' },
    { name: 'Họ tên' },
    { name: 'CMND/CCCD' },
    { name: 'Ngày cấp' },
    { name: 'Số điện thoại' },
    { name: 'Cổ phần sở hữu', sortUp: sortUp, sortDown: sortDown },
    { name: 'Loại hình tham dự' },
    // {name: 'Địa chỉ'},
    // {name: 'Email'},
    { name: 'Trạng thái' },
    { name: 'Xử lý' },
  ];
  return (
    <Fragment>
      <STable>
        <Table header={theads}>
          {table.length > 0 ? (
            table.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.displayCode}</td>
                  <td>{item.name}</td>
                  <td>{item.identityNumber}</td>
                  <td>{item.identityDate}</td>
                  <td>{item.phone}</td>
                  <td>{transformNumberPrinter(item.sharesNumber)}</td>
                  <td>
                    {item.joinType === 'authority'
                      ? t('Ủy quyền')
                      : item.joinType === 'live'
                      ? t('Trực tiếp')
                      : item.joinType === 'online'
                      ? t('Trực tuyến')
                      : item.joinType === 'none'
                      ? t('Chưa checkin')
                      : ''}
                    {/* {!item.isCheckedIn
                      ? t('Chưa check in')
                      : item.joinType === 'online'
                      ? t('Trực tuyến')
                      : t('Trực tiếp')} */}
                  </td>
                  <td>
                    {item.isCheckedIn ? (
                      <span style={{ color: '#00CB82' }}>
                        {t('Đã check in')}
                      </span>
                    ) : (
                      <span style={{ color: '#FF6C6C' }}>
                        {t('Chưa check in')}
                      </span>
                    )}
                  </td>

                  <td className="last_td">
                    {info.manualCheckin &&
                    item.acceptAuthorityFor.length === 0 ? (
                      <ButtonCustom
                        status="green"
                        type="button"
                        onClick={() => {
                          checkin(item, 'check-in');
                        }}
                      />
                    ) : (
                      <div></div>
                    )}

                    {info.qrCode ? (
                      <ButtonCustom
                        status="lightGreen"
                        type="button"
                        onClick={() => {
                          checkin(item, 'qr');
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center' }}>
                {t('Không có dữ liệu')}
              </td>
            </tr>
          )}
        </Table>

        <Pagination
          // handleChangePageSize={getPageSize}
          hideSelectSize={true}
          current={filter.pageNumber}
          total={total}
          pageSize={PAGE_SIZE}
          onChange={getPage}
        />
      </STable>
      {modalType === 'check-in' && (
        <ConfirmModal
          isOpen={modalOpen}
          toggleModal={setModalOpen}
          item={currentItem}
        />
      )}
      {modalType === 'qr' && (
        <QrModal
          isOpen={modalOpen}
          toggleModal={setModalOpen}
          item={currentItem}
        />
      )}
    </Fragment>
  );
};

export default TableCheckInHand;
