/**
 *
 * Modal
 * make by phamthainb
 */
import styled, { keyframes } from 'styled-components';

import React, { Children, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

const Modal = ({
  title,
  label,
  subtitle,
  isOpen,
  toggleModal,
  children,
  overwriteModalStyle,
  hideCloseIcon = false,
  callbackWhenUnmounted,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';

    const handleKeyDown = (e) => {
      if (e.code === 'Escape') {
        callbackWhenUnmounted && callbackWhenUnmounted();
        toggleModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.addEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, toggleModal]);

  return isOpen
    ? ReactDOM.createPortal(
        <SModal>
          <div className="modal" style={overwriteModalStyle}>
            <div className="modal-title">
              <h3 className="title">{t(title)}</h3>
              <span className="text">{t(label)}</span>
              {subtitle &&
                Children.toArray(
                  subtitle.map((s) => (
                    <div>
                      <span className="subtitle">{s}</span>
                      <br />
                    </div>
                  )),
                )}

              {!hideCloseIcon && (
                <svg
                  className="close-modal"
                  onClick={() => {
                    toggleModal(false);
                    callbackWhenUnmounted && callbackWhenUnmounted();
                  }}
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="2.057"
                    height="20.57"
                    transform="matrix(0.707099 -0.707115 0.707099 0.707115 0 1.74316)"
                    fill="#FF5576"
                  />
                  <rect
                    width="2.057"
                    height="20.57"
                    transform="matrix(0.707099 0.707115 -0.707099 0.707115 14.5449 0.288086)"
                    fill="#FF5576"
                  />
                </svg>
              )}
            </div>
            <div className="modal-content">{children}</div>
          </div>
        </SModal>,
        document.body,
      )
    : null;
};

export default Modal;

const modalAnimation = keyframes`
   from {
     opacity: 0;
     transform: translateY(-50%);
   }
   to {
     opacity: 1;
     transform: translateY(0);
   }
 `;

const SModal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 40px;
  overflow: auto;
  z-index: 100;
  .modal {
    margin: 0 auto;
    animation: ${modalAnimation} 0.2s forwards ease-out;
    min-width: 310px;
    max-width: 1200px;
    background: #ffffff;
    border-radius: 0px;
    padding: 44px 0;
    .modal-title {
      width: 100%;
      /* padding: 0 72px; */
      padding: 0 32px;
      position: relative;
      z-index: 10;
      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        text-transform: uppercase;
        color: #333b6a;
        margin: 0;
        padding-right: 30px;
      }
      .text {
        padding-top: 2px;
        color: #718098;
        font-size: 16px;
        line-height: 24px;
      }
      .subtitle {
        font-size: 14px;
        line-height: 16px;
        color: #718098;
        display: inline-block;
        margin-top: 8px;
      }
      .close-modal {
        cursor: pointer;
        position: absolute;
        right: 33px;
        top: 0;
      }
    }
  }
  @media (max-width: 1199px) {
    padding: 40px;
    .modal {
      padding: 40px 0;
      .modal-title {
        padding: 0 32px;
        .close-modal {
          right: 32px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding: 20px;
    .modal {
      padding: 20px 0;
      .modal-title {
        padding: 0 22px;
        .close-modal {
          right: 25px;
        }
        .title {
          flex-direction: column;
          align-items: flex-start;
          span {
            font-size: 18px;
            line-height: 26px;
          }
          .seperate {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 5px;
    .modal {
      padding: 15px 0;
      .modal-title {
        padding: 0 20px;
        .close-modal {
          right: 20px;
        }
      }
    }
  }

  .form__item {
    margin-bottom: 20px;
  }
`;

export const SModalContent = styled.div`
  padding: 0 32px;
  background-color: ${(props) => props.backGroundColor || 'white'};

  margin-top: 44px;

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
`;
