import Wrapper from './Wrapper';
import React from 'react';
import { DefaultText } from './Wrapper';

const TextWithWrapper = ({ inputProps = {}, wrapperProps, register }) => {
  return (
    <Wrapper {...wrapperProps}>
      <DefaultText {...register} {...inputProps} placeholder=" " />
    </Wrapper>
  );
};

export default TextWithWrapper;
