import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  transformNumberPrinter,
  transformPercentNumber,
} from '../../../bases/configs';
import { useVoteConfig } from '../store';
import { publicUrl } from '@configs/publicUrl';
import PdfModal from '@components/PdfModal';
const ResultItemShow = ({ topic, index, isDetail }) => {
  const { t, i18n } = useTranslation();
  let kind = topic && topic.kind;
  const [topicResult, setTopicResult] = useState(null);
  const { data } = useSelector((state) => state.result.result);
  const topicKind123 = data && data.topicKind123;
  const topicKind6 = data && data.topicKind6;
  const [isOpen, setIsOpen] = useState(false);
  const src =
    publicUrl(i18n.language === 'vi' ? topic?.fileUrl : topic?.fileUrl_2) || '';

  const title = i18n.language === 'vi' ? topic?.name : topic?.name_2;
  useEffect(() => {
    if (topic && topic._id && topicKind123) {
      setTopicResult(topicKind123[topic._id]);
    }
    if (topic && topic._id && topicKind6 && topic.kind == 6) {
      setTopicResult(topicKind6[topic._id]);
    }
  }, [topic, topicKind123, topicKind6]);
  const { getVoteLabel } = useVoteConfig();

  if (isDetail)
    return (
      <Styles className={`type${kind}`}>
        <div className="head">
          <p className="title" style={isDetail ? { textAlign: 'left' } : {}}>
            {<span className="bold">{`${t('Nội dung')} ${index + 1}: `}</span>}
            {i18n.language === 'vi'
              ? topic && topic.name
              : (topic && topic.name_2) || ''}
          </p>
        </div>
        <div className={`content`}>
          <div key={index} className="report-item">
            <p className="desc">
              <Trans
                i18nKey="Hợp lệ"
                values={{
                  validNumbers:
                    transformNumberPrinter(
                      topicResult && topicResult.validNumbers,
                    ) || 0,
                  validShares:
                    transformNumberPrinter(
                      topicResult && topicResult.validShares,
                    ) || 0,
                  validSharesPercent: transformPercentNumber(
                    topicResult && topicResult.validSharesPercentPerVoted
                      ? topicResult.validSharesPercentPerVoted * 100
                      : 0,
                  ),
                }}
                defaults=""
              />
            </p>
            <ul className="report-list" style={{ marginBottom: '10px' }}>
              {getVoteLabel(1) && (
                <li className="report-list__item">
                  -{' '}
                  <Trans
                    i18nKey="Tán thành"
                    values={{
                      label: getVoteLabel(1),
                      acceptanceNumbers:
                        transformNumberPrinter(
                          topicResult && topicResult.acceptanceNumbers,
                        ) || 0,
                      acceptanceShares:
                        transformNumberPrinter(
                          topicResult && topicResult.acceptanceShares,
                        ) || 0,
                      acceptanceSharesPercent: transformPercentNumber(
                        topicResult &&
                          topicResult.acceptanceSharesPercentPerValid
                          ? topicResult.acceptanceSharesPercentPerValid * 100
                          : 0,
                      ),
                    }}
                    defaults=""
                  />
                </li>
              )}
              {getVoteLabel(2) && (
                <li className="report-list__item">
                  -{' '}
                  <Trans
                    i18nKey="Không Tán thành"
                    values={{
                      label: getVoteLabel(2),
                      notAcceptanceNumbers:
                        transformNumberPrinter(
                          topicResult && topicResult.notAcceptanceNumbers,
                        ) || 0,
                      notAcceptanceShares:
                        transformNumberPrinter(
                          topicResult && topicResult.notAcceptanceShares,
                        ) || 0,
                      notAcceptanceSharesPercent: transformPercentNumber(
                        topicResult &&
                          topicResult.notAcceptanceSharesPercentPerValid
                          ? topicResult.notAcceptanceSharesPercentPerValid * 100
                          : 0,
                      ),
                    }}
                    defaults=""
                  />
                </li>
              )}
              {getVoteLabel(3) && (
                <li className="report-list__item">
                  -{' '}
                  <Trans
                    i18nKey="Không ý kiến"
                    values={{
                      label: getVoteLabel(3),
                      noIdeaNumbers:
                        transformNumberPrinter(
                          topicResult && topicResult.noIdeaNumbers,
                        ) || 0,
                      noIdeaShares:
                        transformNumberPrinter(
                          topicResult && topicResult.noIdeaShares,
                        ) || 0,
                      noIdeaSharesPercent: transformPercentNumber(
                        topicResult && topicResult.noIdeaSharesPercentPerValid
                          ? topicResult.noIdeaSharesPercentPerValid * 100
                          : 0,
                      ),
                    }}
                    defaults=""
                  />
                </li>
              )}
            </ul>
            <p className="desc">
              <Trans
                i18nKey="Không hợp lệ"
                values={{
                  invalidNumbers: transformNumberPrinter(
                    topicResult && topicResult.invalidNumbers,
                  ),
                  invalidShares: transformNumberPrinter(
                    topicResult && topicResult.invalidShares,
                  ),
                  invalidSharesPercent: transformPercentNumber(
                    topicResult && topicResult.invalidSharesPercentPerVoted
                      ? topicResult.invalidSharesPercentPerVoted * 100
                      : 0,
                  ),
                }}
                defaults=""
              />
            </p>
          </div>
        </div>
      </Styles>
    );
  return (
    <StyleTR>
      <td style={{ textAlign: 'center' }}>{index + 1}</td>
      <td>
        <span onClick={() => setIsOpen(true)} className="pdf-content">
          {i18n.language === 'vi'
            ? topic && topic.name
            : (topic && topic.name_2) || ''}
        </span>
        {isOpen && (
          <PdfModal
            source={src}
            name={title}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </td>
      {getVoteLabel(1) && (
        <>
          <td style={{ textAlign: 'right' }}>
            {transformNumberPrinter(
              topicResult && topicResult.acceptanceShares,
            ) || 0}
          </td>
          <td style={{ textAlign: 'center' }}>
            {transformPercentNumber(
              topicResult && topicResult.acceptanceSharesPercentPerValid
                ? topicResult.acceptanceSharesPercentPerValid * 100
                : 0,
            )}
            %
          </td>
        </>
      )}
      {getVoteLabel(2) && (
        <>
          <td style={{ textAlign: 'right' }}>
            {transformNumberPrinter(
              topicResult && topicResult.notAcceptanceShares,
            ) || 0}
          </td>
          <td style={{ textAlign: 'center' }}>
            {transformPercentNumber(
              topicResult && topicResult.notAcceptanceSharesPercentPerValid
                ? topicResult.notAcceptanceSharesPercentPerValid * 100
                : 0,
            )}
            %
          </td>
        </>
      )}
      {getVoteLabel(3) && (
        <>
          <td style={{ textAlign: 'right' }}>
            {transformNumberPrinter(topicResult && topicResult.noIdeaShares) ||
              0}
          </td>
          <td style={{ textAlign: 'center' }}>
            {transformPercentNumber(
              topicResult && topicResult.noIdeaSharesPercentPerValid
                ? topicResult.noIdeaSharesPercentPerValid * 100
                : 0,
            )}
            %
          </td>
        </>
      )}
    </StyleTR>
  );
};

export default ResultItemShow;

const Styles = styled.div`
  margin-bottom: 30px;
  width: 100%;
  padding: 40px;
  > * {
    width: 768px;
    max-width: 100%;
    margin: 0 auto;
  }

  /* background: white; */

  box-shadow: 0px 10px 50px 0px rgb(113 128 152 / 10%);
  :last-child {
    margin-bottom: unset;
  }
  &.type1 {
    .content {
      /* padding: 0 28px; */
      /* background: white;
      box-shadow: 0px 10px 50px 0px rgba(113, 128, 152, 0.1); */
    }
  }
  &.type3 {
    /* background: #ffffff; */
    /* box-shadow: 0px 10px 50px 0px rgba(113, 128, 152, 0.1); */
    /* padding: 28px; */

    .head {
      padding-bottom: 0%;
      .title {
        font-size: 16px;
        line-height: 28px;
        color: #333b6a;
        /* font-family: 'oswald_bold'; */
        position: relative;
        /* padding-left: 32px; */
        .number {
          width: 20px;
          height: 20px;
          background: linear-gradient(135deg, #2bd25e 0%, #06c8df 100%);
          display: block;
          position: absolute;
          top: 4px;
          left: 0;
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          text-align: center;
          border-radius: 50%;
        }
      }
    }
    .content {
      padding: 6px;
      /* padding-left: 32px; */
    }
  }
  &.type2 {
    .head {
      padding-bottom: 0%;
    }
    .content {
      /* border: 1px solid rgba(227, 232, 245, 1); */
      /* .report-item {
        background: white;
        padding: 28px;
      } */
    }
  }
  .head {
    /* padding-bottom: 12px; */
    .title {
      font-size: 20px !important;
      line-height: 30px;
      color: #333b6a;
      font-family: oswald;
    }
  }
  .content {
    .report-item {
      .desc {
        padding-left: 32px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        color: #828ea6;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }

        &:after {
          content: url('/images/result/item_styles.png');
          position: absolute;
          top: 2px;
          left: 0;
        }
      }
      ul {
        padding-left: 22px;
        li {
          font-size: 16px;
          line-height: 24px;
          color: #828ea6;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
  }
  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
  @media only screen and (max-width: 479px) {
  }
`;

const StyleTR = styled.tr`
  .pdf-content {
    cursor: pointer;
    justify-content: flex-start;
    &:hover {
      text-decoration: underline;
      color: #00cb82;
    }
  }
`;
