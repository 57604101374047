export const vi2 = {
  'Check thủ công': 'Check thủ công',
  'Cấu hình hệ thống': 'Cấu hình hệ thống',
  'Hệ thống Cổ đông': 'Hệ thống Cổ đông',
  'Tên Đại hội': 'Tên Đại hội',
  'Ủy quyền online': 'Ủy quyền online',
  'Tải xuống kết quả': 'Tải xuống kết quả',
  'Login khách mời': 'Login khách mời',
  'Quên mật khẩu': 'Quên mật khẩu',
  'Đại hội Cổ đông': 'Đại hội Cổ đông',
  'Số lượng\n(Cổ đông)': 'Số lượng\n(Cổ đông)',
  'Số CP đại diện sở hữu \n(Cổ phần)': 'Số CP đại diện sở hữu \n(Cổ phần)',
  'Tỉ lệ %/tổng CP của Công ty': 'Tỉ lệ %/tổng CP của Công ty',
  'Báo cáo kiểm tra tư cách Cổ đông': 'Báo cáo kiểm tra tư cách Cổ đông',
  'Cổ đông được mời họp': 'Cổ đông được mời họp',
  'Cổ đông/đại diện ủy quyền tham dự': 'Cổ đông/đại diện ủy quyền tham dự',
  'Cổ đông vắng mặt': 'Cổ đông vắng mặt',
  'Tổng số Cổ đông tham dự': 'Tổng số Cổ đông tham dự',
  'Tổng số Cổ phần tham dự': 'Tổng số Cổ phần tham dự',
  'Tổng số Cổ đông tham dự trực tiếp': 'Tổng số Cổ đông tham dự trực tiếp',
  'Tổng số Cổ đông tham dự trực tuyến': 'Tổng số Cổ đông tham dự trực tuyến',
  'Tổng số Cổ đông ủy quyền tham dự': 'Tổng số Cổ đông ủy quyền tham dự',
  'Cổ đông - Khách mời đang online': 'Cổ đông - Khách mời đang online',
  'Các Cổ đông và đại diện Cổ đông tham dự đều có đủ tư cách theo quy định của pháp luật và quy chế tổ chức Đại hội':
    'Các Cổ đông và đại diện Cổ đông tham dự đều có đủ tư cách theo quy định của pháp luật và quy chế tổ chức Đại hội',
  'Thông qua thủ tục khai mạc': 'Thông qua thủ tục khai mạc',
  'Kết quả biểu quyết tại Đại hội': 'Kết quả biểu quyết tại Đại hội',
  'Tán thành ': 'Tán thành ',
  'Không tán thành ': 'Không tán thành ',
  'Không ý kiến ': 'Không ý kiến ',
  'Số CP biểu quyết': 'Số CP biểu quyết',
  'Nội dung biểu quyết': 'Nội dung biểu quyết',
  'Bầu cử': 'Bầu cử',
  'Thủ tục bế mạc': 'Thủ tục bế mạc',
  'Trang chủ': 'Trang chủ',
  'Khai báo nội dung Đại hội': 'Khai báo nội dung Đại hội',
  'Khai báo BC - Tờ trình': 'Khai báo BC - Tờ trình',
  'Khai báo thủ tục bế mạc': 'Khai báo thủ tục bế mạc',
  'Khai báo tài liệu Đại hội': 'Khai báo tài liệu Đại hội',
  'Xử lý ủy quyền': 'Xử lý ủy quyền',
  'Thiết lập thông tin ủy quyền': 'Thiết lập thông tin ủy quyền',
  'Kiểm tra tư cách Cổ đông': 'Kiểm tra tư cách Cổ đông',
  'Check in thủ công': 'Check in thủ công',
  'Xuất biên bản KTTC CĐ': 'Xuất biên bản KTTC CĐ',
  'Quản lý kết quả biểu quyết': 'Quản lý kết quả biểu quyết',
  'Biên bản - Nghị quyết ĐH': 'Biên bản - Nghị quyết ĐH',
  'Quản lý ý kiến Cổ đông': 'Quản lý ý kiến Cổ đông',
  'Quản lý khách mời': 'Quản lý khách mời',
  'Hệ thống': 'Hệ thống',
  'Thiết lập biểu mẫu': 'Thiết lập biểu mẫu',
  'Tài khoản quản trị': 'Tài khoản quản trị',
  'GIẢI PHÁP TỔ CHỨC': 'GIẢI PHÁP TỔ CHỨC',
  'ĐẠI HỘI CỔ ĐÔNG': 'ĐẠI HỘI CỔ ĐÔNG',
  login_desc_message: `Bvote là giải pháp trọn gói hàng đầu cho kỳ đại hội cổ đông sắp tới của doanh nghiệp. Với dịch vụ hỗ trợ công bố thông tin và biểu quyết online ứng dụng công nghệ Blockchain, Bvote bảo đảm tính minh bạch của kết quả biểu quyết cho các Cổ đông.`,
  'Dành cho tổ chức phát hành': 'Dành cho tổ chức phát hành',
  'Tài liệu': 'Văn bản',
  'Trực tuyến': 'Trực Tuyến',
};
export const en2 = {
  'Check thủ công': 'Manual check',
  'Cấu hình hệ thống': 'System configuration',
  'Hệ thống Cổ đông': 'Shareholder System',
  'Tên Đại hội': 'Congress name',
  'Ủy quyền online': 'Online authorization',
  'Tải xuống kết quả': 'Download results',
  'Login khách mời': 'Guest login',
  'Quên mật khẩu': 'Forgot password',
  'Đại hội Cổ đông': "Shareholders' Meeting",
  'Số lượng\n(Cổ đông)': 'Amount\n(Shareholders)',
  'Số CP đại diện sở hữu \n(Cổ phần)':
    'Amount of shares representing ownership \n(Shares)',
  'Tỉ lệ %/tổng CP của Công ty': 'Percentage of total shares of the Company',
  'Báo cáo kiểm tra tư cách Cổ đông': 'Stakeholder Status Check Report',
  'Cổ đông được mời họp': 'Shareholders are invited',
  'Cổ đông/đại diện ủy quyền tham dự':
    'Authorized shareholder/representative to attend',
  'Cổ đông vắng mặt': 'Absent shareholders',
  'Tổng số Cổ đông tham dự': 'Total number of Shareholders attending',
  'Tổng số Cổ phần tham dự': 'Total Shares Attended',
  'Tổng số Cổ đông tham dự trực tiếp':
    'Total number of shareholders attending directly',
  'Tổng số Cổ đông tham dự trực tuyến':
    'Total number of Shareholders attending online',
  'Tổng số Cổ đông ủy quyền tham dự':
    'Total number of Authorized Shareholders to attend',
  'Cổ đông - Khách mời đang online': 'Shareholder - Guest is online',
  'Các Cổ đông và đại diện Cổ đông tham dự đều có đủ tư cách theo quy định của pháp luật và quy chế tổ chức Đại hội':
    'The Shareholders and representatives of Shareholders attending are all qualified in accordance with the law and regulations on organization of the General Meeting',
  'Thông qua thủ tục khai mạc': 'Pass Opening documents',
  'Kết quả biểu quyết tại Đại hội': 'Results of voting',
  'Tán thành ': 'Approved ',
  'Không tán thành ': 'Disapprove ',
  'Không ý kiến ': 'No idea ',
  'Số CP biểu quyết': 'Voting shares',
  'Nội dung biểu quyết': 'Voting content',
  'Bầu cử': 'Voting',
  'Thủ tục bế mạc': 'Finishing procedure',
  'Trang chủ': 'Home',
  'Khai báo nội dung Đại hội': 'Declaring the contents',
  'Khai báo BC - Tờ trình': 'Declaration Report',
  'Khai báo thủ tục bế mạc': 'Declaring closing procedure',
  'Khai báo tài liệu Đại hội': 'Declaring documents',
  'Xử lý ủy quyền': 'Authorization handling',
  'Thiết lập thông tin ủy quyền': 'Set up authorization infor',
  'Kiểm tra tư cách Cổ đông': 'Check Shareholder status',
  'Check in thủ công': 'Manual check-in',
  'Xuất biên bản KTTC CĐ': 'Export Minutes',
  'Quản lý kết quả biểu quyết': 'Manage voting results',
  'Biên bản - Nghị quyết ĐH': 'Minutes - Resolution',
  'Quản lý ý kiến Cổ đông': 'Management of opinions',
  'Quản lý khách mời': 'Guest Management',
  'Hệ thống': 'System',
  'Thiết lập biểu mẫu': 'Set up form',
  'Tài khoản quản trị': 'Admin Account',
  'GIẢI PHÁP TỔ CHỨC': 'ORGANIZATION SOLUTIONS',
  'ĐẠI HỘI CỔ ĐÔNG': 'SHAREHOLDER MEETING',
  login_desc_message: `Bvote is the leading package solution for the company's upcoming shareholder meeting. With support services for information disclosure and online voting using Blockchain technology, Bvote ensures the transparency of voting results for Shareholders.`,
  'Dành cho tổ chức phát hành': 'For issuers',
};
