import { ReactComponent as Info1 } from 'assets/icons/info-1.svg';
import { ReactComponent as Info2 } from 'assets/icons/info-2.svg';
import { ReactComponent as Info3 } from 'assets/icons/info-3.svg';
import { ReactComponent as Info4 } from 'assets/icons/info-4.svg';
import { ReactComponent as Info5 } from 'assets/icons/info-5.svg';
import { ReactComponent as Info6 } from 'assets/icons/info-6.svg';
import { ReactComponent as Info7 } from 'assets/icons/info-7.svg';
import { ReactComponent as Info8 } from 'assets/icons/info-8.svg';
import { UIList } from 'components/bases/UI/List';
import { UITable } from 'components/bases/UI/Table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import io from 'socket.io-client'; ///
import { formatNumber } from 'states/libs';
import styled, { css } from 'styled-components';
import { URL_RESULT } from '../../../states/common/constants';

const SectionInfo = ({ pathname, isDetail, props }) => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.result.result.data);
  const [online, setUsersOnline] = useState(0); ///////
  // const totalSharesJoinedVotedAuthority =
  //   data && data.totalSharesJoinedVotedAuthority
  //     ? data.totalSharesJoinedVotedAuthority
  //     : 0;
  // const totalSharesJoinedVotedLive =
  //   data && data.totalSharesJoinedVotedLive
  //     ? data.totalSharesJoinedVotedLive
  //     : 0;
  const totalShares = data && data.totalShares ? data.totalShares : 0;
  const totalJoinedVotedLive =
    data && data.totalJoinedVotedLive ? data.totalJoinedVotedLive : 0;
  const totalJoinedVotedOnline =
    data && data.totalJoinedVotedOnline ? data.totalJoinedVotedOnline : 0;
  const totalJoinedVotedAuthority =
    data && data.totalJoinedVotedAuthority ? data.totalJoinedVotedAuthority : 0;
  // const totalSharesJoinedVotedOnline =
  //   data && data.totalSharesJoinedVotedOnline
  //     ? data.totalSharesJoinedVotedOnline
  //     : 0;
  const totalSharesJoinedVoted = data
    ? data.totalSharesJoinedVotedAuthority +
      data.totalSharesJoinedVotedLive +
      data.totalSharesJoinedVotedOnline
    : 0;
  // const percent = data && data.percent ? data.percent : 0;
  useEffect(() => {
    const socket = io.connect(URL_RESULT);
    const admin = JSON.parse(localStorage.getItem('adminId'));
    const conferenceId = admin && admin.conferenceId ? admin.conferenceId : '';
    socket.emit('getUsersOnline', { conferenceId });
    socket.on('usersOnline', (usersOnline) => {
      if (usersOnline && usersOnline.conferenceId === conferenceId) {
        setUsersOnline(usersOnline.count);
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  // const totalSharesJoinedVote = totalSharesJoinedVotedOnline + totalSharesJoinedVotedLive + totalSharesJoinedVotedAuthority;
  // const totalSharesJoinedVotePercent = (totalSharesJoinedVote/totalShares)*100;

  const headers = [
    t('Stt'),
    t('Nội dung'),
    t('Số lượng\n(Cổ đông)'),
    t('Số CP đại diện sở hữu \n(Cổ phần)'),
    t('Tỉ lệ %/tổng CP của Công ty'),
  ];

  const totalShareholderJoined =
    totalJoinedVotedLive + totalJoinedVotedOnline + totalJoinedVotedAuthority;
  const tableData = [
    {
      label: t('Cổ đông được mời họp'),
      amount1: data?.totalShareholder || 0,
      amount2: totalShares,
      amount3: totalShares ? 1 : 0,
    },
    {
      label: t('Cổ đông/đại diện ủy quyền tham dự'),
      amount1: totalShareholderJoined,
      amount2: totalSharesJoinedVoted,
      amount3: totalSharesJoinedVoted / totalShares,
    },
    {
      label: t('Cổ đông vắng mặt'),
      amount1: (data?.totalShareholder || 0) - totalShareholderJoined,
      amount2: totalShares - totalSharesJoinedVoted,
      amount3: (totalShares - totalSharesJoinedVoted) / totalShares,
    },
  ];

  const detailArr = [
    {
      label: t('Tổng số Cổ đông'),
      amount: data?.totalShareholder || 0,
      icon: <Info1 />,
    },
    {
      label: t('Tổng số Cổ phần'),
      amount: totalShares,
      icon: <Info2 />,
    },
    {
      label: t('Tổng số Cổ đông tham dự'),
      amount: totalShareholderJoined,
      icon: <Info3 />,
    },
    {
      label: t('Tổng số Cổ phần tham dự'),
      amount: totalSharesJoinedVoted,
      icon: <Info4 />,
      ratio: (totalSharesJoinedVoted * 100) / totalShares,
    },
    {
      label: t('Tổng số Cổ đông tham dự trực tiếp'),
      amount: totalJoinedVotedLive,
      icon: <Info5 />,
    },
    {
      label: t('Tổng số Cổ đông tham dự trực tuyến'),
      amount: totalJoinedVotedOnline,
      icon: <Info6 />,
    },
    {
      label: t('Tổng số Cổ đông ủy quyền tham dự'),
      amount: totalJoinedVotedAuthority,
      icon: <Info7 />,
    },
    {
      label: t('Cổ đông - Khách mời đang online'),
      amount: online,
      icon: <Info8 />,
    },
  ];

  if (isDetail)
    return (
      <Styles2>
        <UIList items={detailArr} className="info-cover">
          {(item) => {
            return <CardInfo info={item} />;
          }}
        </UIList>
      </Styles2>
    );
  return (
    <Styles label={t('Báo cáo kiểm tra tư cách Cổ đông')} {...props}>
      <UITable headers={headers} items={tableData}>
        {(item, ind) => {
          return (
            <tr>
              <td style={{ textAlign: 'center' }}>{ind + 1}</td>
              <td>{item.label}</td>
              <td style={{ textAlign: 'right' }}>
                {formatNumber(item.amount1)}
              </td>
              <td style={{ textAlign: 'right' }}>
                {formatNumber(item.amount2)}
              </td>
              <td style={{ textAlign: 'center' }}>
                {formatNumber(item.amount3 * 100, 2)}%
              </td>
            </tr>
          );
        }}
      </UITable>
      <p className="info-note">
        (
        {t(
          'Các Cổ đông và đại diện Cổ đông tham dự đều có đủ tư cách theo quy định của pháp luật và quy chế tổ chức Đại hội',
        )}
        )
      </p>
    </Styles>
  );
};

export default SectionInfo;

export const Card = ({ header, label, children, ...props }) => {
  const conference = useSelector(
    (state) => state.result?.result?.conferenceInfo,
  );
  return (
    <SCard {...props}>
      {header && <h2 {...props}>{header}</h2>}
      <div className="card-content">
        {label && (
          <div className="content-top">
            <img src={conference.logoUrl || '/images/bvote_logo.png'} alt="" />
            <p>{label}</p>
          </div>
        )}
        {children}
      </div>
    </SCard>
  );
};

const Styles = styled(Card)`
  margin: 56px 0 32px;
  p {
    font-size: 14px;
    margin: 8px 0 0 !important;
    color: ${(props) => props.theme.textColor || ''};
  }
`;

const SCard = styled.div`
  h2 {
    text-align: center;
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    color: ${(props) => {
      return props.theme.textColor || '';
    }};
  }
  .card-content {
    padding: 24px 20px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid white;
  }

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 20px;
    img {
      height: 40px;
    }
    p {
      font-size: 20px;
      padding-right: 20px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: end;
      margin: 0;
    }
  }

  ${({ isDetail }) =>
    isDetail &&
    css`
      .card-content {
        background: rgba(255, 255, 255, 0.5);
        border: unset;
        padding: 0;
        width: 100%;
      }

      @media only screen and (max-width: 767px) {
        .card-content {
          padding: 0 !important;
        }
      }
    `}

  @media only screen and (max-width: 767px) {
    .content-top {
      p {
        font-size: 16px;
        padding-right: 35px;
      }
    }

    .card-content {
      padding: 20px 15px;
    }
  }

  @media only screen and (display-mode: fullscreen) {
    margin: 500px 0 !important;
    line-height: 2 !important;
  }
`;

const Styles2 = styled.div`
  margin: 40px 0 32px;
  .info-cover {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  @media only screen and (max-width: 767px) {
    .info-cover {
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
  }

  @media only screen and (max-width: 500px) {
    .info-cover {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const CardInfo = ({ info }) => {
  return (
    <SCardInfo>
      <div className="info-icon">{info.icon}</div>
      <div className="info-amount">{formatNumber(info.amount)}</div>
      <div className="info-label">{info.label}</div>
      {info.ratio && (
        <div className="info-ratio">
          Tỷ lệ: <span>{formatNumber(info.ratio % 100, 2)}%</span>
        </div>
      )}
    </SCardInfo>
  );
};

const SCardInfo = styled.div`
  padding: 22px 32px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 32px rgba(113, 128, 152, 0.08);
  border-left: 4px solid transparent;

  .info-icon {
    height: 72px;
    width: 72px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-amount {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 700;
  }

  .info-label {
    color: #718098;
    font-size: 16px;
  }

  .info-ratio {
    font-size: 16px;
    font-weight: 700;
    span {
      color: #00cb82;
    }
  }

  :hover {
    border-left: 4px solid #f47f20;

    .info-icon {
      background: #00cb82;
      svg {
        * {
          fill: white;
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    padding: 15px;

    .info-icon {
      width: 40px;
      height: 40px;
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
`;
