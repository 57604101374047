import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  signOut,
  setOTPForm,
} from './../../states/duck/pages/loginPage/actions';
import { useDispatch } from 'react-redux';
import Language from '@components/language';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Header = ({ pathname }) => {
  const admin = JSON.parse(localStorage.getItem('adminId'));
  const name = admin && admin.name ? admin.name : '';
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const signOutFn = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('adminId');
    history.push('/login');
    dispatch(signOut());
    dispatch(setOTPForm(false));
  };
  return (
    <Styles>
      <div className="header-left">
        <label>
          {t('Xin chào')}, {name}
        </label>
        <p>
          {t('Đại hội Cổ đông')} {new Date().getFullYear()}
        </p>
      </div>
      <div className="header-right">
        <Language />
        <div className="avatar">
          <img src="/images/avatar.png" />
          <img src="/images/header_icon_bar.png" />
          <div className="sign-out">
            <div>
              <Link to="/doi-mat-khau">{t('Đổi mật khẩu')}</Link>
            </div>
            <div
              onClick={() => {
                signOutFn();
              }}
            >
              {t('Đăng xuất')}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default Header;

const Styles = styled.div`
  padding: 0 0 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left {
    label {
      font-size: 12px;
      color: #718098;
    }
    p {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .avatar {
    display: flex;
    align-items: center;
    gap: 8px;

    .sign-out {
      display: none;
      position: absolute;
      z-index: 1;
      top: 50px;
      right: 0;
      padding: 4px 8px;
      border-radius: 4px;
      background: white;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      > div {
        padding: 4px 8px;
        :hover {
          color: #00cb82;
        }
      }
    }

    :hover {
      .sign-out {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding-left: 40px;
  }

  @media only screen and (max-width: 480px) {
    .header-left {
      p {
        font-size: 18px;
      }
    }
  }
`;
