import { publicUrl } from '@configs/publicUrl';
import { signOut } from '@duck/pages/loginPage/actions';
import { SHome } from '@styled/admin/homePage/index';
import React, { Children, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/bases/shared/button';
import Input from '../../../../components/bases/shared/input';
import Section from '../../../../components/bases/shared/section';
import {
  getInfoApi,
  getInfoConferenceApi,
} from '../../../../states/duck/pages/admin/homeReducer/actions';
import DropImage from '../../../bases/shared/dropzone/dropImage';
import ErrorMessage from '../../../bases/shared/input/ErrorMessage';
import { ActiveSVG, DeactiveSVG, inputs, registeredFields } from './data.jsx';
import * as constants from '../../../bases/constants';

const objKey = 'conference';

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.admin.home.info);
  const [isDefault, setDefault] = useState(true);

  // console.log("info", info);
  const onChange = (value, action) => {
    action(value);
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    register(objKey + '.bannerUrl');
    register(objKey + '.logoUrl');
  }, [register]);

  // set init values
  useEffect(() => {
    if (info) {
      setBanner({
        ...banner,
        url: info.urlBanner ? publicUrl(info.urlBanner) : null,
      });
      setLogo({ ...logo, url: info.urlLogo ? publicUrl(info.urlLogo) : null });
      setValue(objKey, {
        ...info,
        // confirmPermissionDate: !isNaN(new Date(info.confirmPermissionDate))
        //   ? new Date(info.confirmPermissionDate).toLocaleDateString('en-US')
        //   : null,
      });
      if (info.logoWidth === 139 && info.logoHeight === 44) {
        setDefault(true);
      } else {
        setDefault(false);
        setValue('conference.logoWidth', info.logoWidth);
        setValue('conference.logoHeight', info.logoHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, setValue]);

  // const admin = JSON.parse(localStorage.getItem('adminId'));
  // const conferenceId = admin && admin.conferenceId ? admin.conferenceId : null;
  const signOutFn = () => {
    localStorage.removeItem('token');
    dispatch(signOut());
    // dispatch(setOTPForm(false));
  };

  // call api to get value
  useEffect(() => {
    dispatch(getInfoConferenceApi());
  }, [dispatch]);

  // SUBMIT
  const onSubmit = (values) => {
    const form = new FormData();
    // formData append value without nested value
    Object.entries(values.conference).forEach(([key, value]) => {
      if (registeredFields.includes(objKey + '.' + key)) {
        form.append(key, value || '');
      }
    });
    form.append('logoWidth', isDefault ? +139 : +values.conference.logoWidth);
    form.append('logoHeight', isDefault ? +44 : +values.conference.logoHeight);
    form.append(
      'address',
      JSON.stringify({
        details: values.conference.address.details,
      }),
    );
    form.append(
      'addressMeeting',
      JSON.stringify({
        details: values.conference.addressMeeting.details,
      }),
    );
    form.append(
      'timeMeeting',
      JSON.stringify({
        ...values.conference.timeMeeting,
      }),
    );
    form.append(
      'timeMeeting_2',
      JSON.stringify({
        ...values.conference.timeMeeting_2,
      }),
    );
    logo.file && form.append('logo', logo.file);
    banner.file && form.append('banner', banner.file);

    if (info && info._id) {
      form.append('id', info._id);
    }

    dispatch(getInfoApi(form, signOutFn));
  };

  const [banner, setBanner] = useState({
    url: null,
    file: null,
  });
  const [logo, setLogo] = useState({
    url: null,
    file: null,
  });

  const onImgChange = (e) => (type) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (type === 'logoUrl') {
        setLogo({ ...logo, url: URL.createObjectURL(file), file });
      } else {
        setBanner({ ...banner, url: URL.createObjectURL(file), file });
      }
      setValue(objKey + '.' + type, file);
    }
  };

  return (
    <SHome>
      <Section header={t('Khai báo thông tin cơ bản')}>
        <div className="dropzone">
          <div className="dropzone__item left">
            <DropImage
              path={logo.url}
              mini={true}
              label={t('Logo công ty')}
              onChange={(e) => onImgChange(e)('logoUrl')}
            />
            <p className="title">{t('Cài đặt kích thước logo hiển thị')}</p>
            <div className="control-container" onClick={() => setDefault(true)}>
              {isDefault ? <ActiveSVG /> : <DeactiveSVG />}{' '}
              <p className="text">{t('Mặc định')} (139x44 pixel)</p>
            </div>
            <div className="control-container big">
              <div className="select" onClick={() => setDefault(false)}>
                {!isDefault ? <ActiveSVG /> : <DeactiveSVG />}{' '}
                <p className="text">{t('Tùy chỉnh')}</p>
              </div>
              <div className="controls">
                <p className="desc">{t('Chiều rộng')}:</p>
                <div className="group-items">
                  <Controller
                    control={control}
                    name="conference.logoWidth"
                    rules={{
                      required: !isDefault
                        ? constants.VALIDATE_REQUIRE
                        : undefined,
                    }}
                    render={({ field }) => {
                      return (
                        <input
                          type="number"
                          {...field}
                          disabled={isDefault}
                          style={{
                            borderColor:
                              !isDefault && !!errors?.conference?.logoWidth
                                ? 'red'
                                : '#ccc',
                          }}
                          // value={width}
                          // onChange={(e) => onChange(e.target.value, setWidth)}
                        />
                      );
                    }}
                  />

                  <p className="desc">pixel</p>
                </div>
              </div>
              <div className="controls">
                <p className="desc">{t('Chiều cao')}:</p>
                <div className="group-items">
                  <Controller
                    control={control}
                    name="conference.logoHeight"
                    rules={{
                      required: !isDefault
                        ? constants.VALIDATE_REQUIRE
                        : undefined,
                    }}
                    render={({ field }) => {
                      return (
                        <input
                          type="number"
                          {...field}
                          disabled={isDefault}
                          style={{
                            borderColor:
                              !isDefault && !!errors?.conference?.logoHeight
                                ? 'red'
                                : '#ccc',
                          }}
                          // value={height}
                          // onChange={(e) => onChange(e.target.value, setHeight)}
                        />
                      );
                    }}
                  />

                  <p className="desc">pixel</p>
                </div>
              </div>
            </div>
            <ErrorMessage errors={errors} field={`${objKey}.logoUrl`} />
          </div>
          <div className="dropzone__item right">
            <DropImage
              path={banner.url}
              label={t('Banner công ty')}
              onChange={(e) => onImgChange(e)('bannerUrl')}
            />
            <ErrorMessage errors={errors} field={`${objKey}.bannerUrl`} />
          </div>
        </div>

        <form className="form">
          {Children.toArray(
            inputs.map((i) => {
              return (
                <div className="form-item">
                  {i.type === 'date' ? (
                    <Input.Date
                      name={i.name}
                      {...{ control, setValue, watch }}
                      wrapperProps={{
                        label: t(i.label),
                      }}
                      rules={{
                        required: t(i.require),
                        ...i.rules,
                      }}
                    />
                  ) : ['tel', 'text', 'number'].includes(i.type) ? (
                    <Input.Text
                      wrapperProps={{
                        label: t(i.label),
                        icon: i.icon,
                      }}
                      inputProps={{
                        type: i.type,
                      }}
                      register={register(i.name, {
                        required: i.require,
                        ...i.rules,
                      })}
                    />
                  ) : i.type === 'time' ? (
                    <Input.Time
                      {...{ control, setValue, watch }}
                      wrapperProps={{
                        label: t(i.label),
                      }}
                      rules={{
                        required: i.require,
                        ...i.rules,
                      }}
                    />
                  ) : null}
                  <ErrorMessage errors={errors} field={i.name} />
                </div>
              );
            }),
          )}
        </form>

        <div className="group-button">
          {/* <Button
            style={{
              backgroundColor: 'white',
              color: '#718098',
              border: '1px solid #00CB82',
            }}
          >
            {t('Hủy')}
          </Button> */}
          <Button
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          >
            {t('Lưu')}
          </Button>
        </div>
      </Section>
    </SHome>
  );
};
export default HomePage;

// const dateFormat1 = (date) => {
//   let stringDate = new Date(date);
//   let d = stringDate.getDate();
//   let m = stringDate.getMonth() + 1;
//   let y = stringDate.getFullYear();
//   return d + '/' + m + '/' + y;
// };
// const dateFormat2 = (date) => {
//   let d = date.split('/')[0];
//   let m =date.split('/')[1];
//   let y = date.split('/')[2]
//   return m + '/' + d + '/' + y;
// };
