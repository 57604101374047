import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Modal from '@components/modal';

// Setting up worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PdfModal = ({ source, name, isOpen, setIsOpen }) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const { t } = useTranslation();

  // @ts-ignore
  return (
    <Modal
      overwriteModalStyle={{ maxWidth: '800px' }}
      isOpen={isOpen}
      toggleModal={setIsOpen}
      title={name}
    >
      <Document
        file={source}
        loading={'Loading ...'}
        renderMode={'canvas'}
        onLoadSuccess={onDocumentLoadSuccess}
        error={t('Xảy ra sự cố khi tải tệp. Vui lòng thử lại')}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <PageWrapper key={`page${index + 1}`}>
            <Page
              className={'doc-page'}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              scale={2.0}
            />
          </PageWrapper>
        ))}
      </Document>
    </Modal>
  );
};

export default PdfModal;

const PageWrapper = styled.div`
  margin-bottom: 5px;
  max-width: 100%;

  .doc-page {
    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;

      > canvas {
        width: 100% !important;
        height: auto !important;
      }
    }

    .react-pdf__Page__textContent {
      width: 100% !important;
    }
  }
`;
