import styled from 'styled-components';

export const Styles = styled.div`
  input {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e3e8f5;
  }

  .module {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding-bottom: 50px;

    .module-content {
      width: 100%;
      max-width: 500px;
      padding: 30px 20px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 0 10px 0px #e3e3e3;

      margin: 0;
      .title {
        font-size: 18px;
        color: #00b775;
        font-weight: 500;
        margin-bottom: 8px;
      }
      button {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
        svg {
          width: 18px;
          height: auto;
          margin-bottom: -3px;
          path {
            fill: #fff !important;
          }
        }
      }
    }
    .module-qr {
      width: 100%;
      max-width: 300px;
      background: #fff;
      padding: 30px;
      border-radius: 6px;
      box-shadow: 0 0 10px 0px #e3e3e3;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qr-code {
        width: 100%;
        max-width: 250px;
        height: auto;
        aspect-ratio: 1/1;
        border: 1px solid gray;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      a {
        width: 100%;
      }
      button {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;
        width: 100%;
        margin-top: 16px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .module {
      flex-direction: column;
      .module-content {
        max-width: 100%;
      }
      .module-qr {
        max-width: 100%;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .module {
      .module-content {
        padding: 20px 15px;
        .title {
          font-size: 16px;
        }
      }
      .module-qr {
        padding: 30px 15px;
      }
    }
  }
`;
