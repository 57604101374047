import styled from 'styled-components';

export const SHome = styled.div`
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 -16px -20px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      margin-bottom: -15px;
    }
    .form-item {
      margin: 0 16px 20px;
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }

  .group-button {
    margin: 26px -8px -8px;
    text-align: center;
    & > button {
      margin: 0 8px 8px;
    }
    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }

  .dropzone {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 -16px;
    gap: 19px;
    &__item {
      margin: 0 0 42px;
      display: flex;
      flex-direction: column;
      align-items: center;
      label {
        width: 100%;
        max-width: none;
      }
    }
  }
  .left {
    width: 20%;
    .title {
      width: 100%;
      color: var(--333-b-6-a, #333b6a);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 100% */
      text-align: left;
    }
    .control-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 8px;
      align-items: center;
      background: var(--background-neutral-1, #fff);
      margin-bottom: 4px;
      .text {
        color: var(--333-b-6-a, #333b6a);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 100% */
      }
      .checkbox {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }
    .big {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      .select {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .desc {
        color: var(--333-b-6-a, #333b6a);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        margin: 0;
      }
      .controls {
        padding-left: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        &:last-child {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .group-items {
          display: flex;
          justify-content: space-between;
          width: fit-content;
          gap: 3px;
          align-items: baseline;
          input {
            width: 55px;
            height: 27px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .right {
    width: 80%;
  }
`;
