import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import {transformNumberPrinter} from '../../bases/configs';
import Pagination from '@components/pagination';
import { UITable } from 'components/bases/UI/Table';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ResultComulativeShow from './components/ResultComulativeShow';
import ResultItemShow from './components/ResultItemShow';
import { Card } from './sectionInfo';
import { ResultHeaders } from './sectionOpeningResult';
import PdfModal from '@components/PdfModal';
import { publicUrl } from '@configs/publicUrl';

const SectionReport = ({ dataShow, isDetail }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const conference = useSelector(
    (state) => state.result?.result?.conferenceInfo,
  );
  const data = useSelector((state) => state.result.result.data);
  const topic2 = useSelector((state) => state.result.result.topic2);
  const topic4 = useSelector((state) => state.result.result.topic4);
  const topic6 = useSelector((state) => state.result.result.topic6);
  const config = useSelector(
    (state) => state.admin.configSystem.info.homeDisplaySetting,
  );
  const pageSize = config?.topic2Table || 5;
  const pageSizeTopic6 = config?.topic4Table || 5;

  let dataShow4 = [];
  const cumulativeQuestions = data && data.topicKind4;
  if (cumulativeQuestions) dataShow4 = Object.values(cumulativeQuestions);
  // console.log(dataShow);
  dataShow4.sort((a, b) => {
    if (a.topic.order >= b.topic.order) {
      return 1;
    } else if (a.topic.order < b.topic.order) {
      return -1;
    }
    return 0;
  });
  if (topic2)
    topic2.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      }
      return 0;
    });
  if (topic4)
    topic4.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      }
      return 0;
    });
  if (topic6)
    topic6.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      }
      return 0;
    });
  // topic4.sort((a, b) => {
  //   let createA = new Date(a.createdAt).getTime();
  //   let createB = new Date(b.createdAt).getTime();
  //   if (createA > createB) {
  //     return 1;
  //   } else if (createA < createB) {
  //     return -1;
  //   }
  //   return 0;
  // });
  if (isDetail)
    return (
      <>
        <Styles2 header={t('Kết quả kiểm phiếu')} isDetail>
          {(topic2 || []).map((item, index) => {
            return (
              <ResultItemShow
                key={index}
                index={index}
                topic={item}
                isDetail={isDetail}
              />
            );
          })}
        </Styles2>
        {/* <h2 className="topic-label">Bầu cử</h2> */}
        <Styles2 header={t('Bầu cử')} isDetail>
          <div className="topic topic6">
            {(topic6 || []).map((item, index) => {
              return (
                <ResultItemShow
                  key={index}
                  index={index}
                  topic={item}
                  isDetail={isDetail}
                />
              );
            })}
          </div>

          <div className="topic">
            <p className="title hidden">{t('Bầu cử')}</p>
            {(topic4 || []).map((item, index) => {
              return (
                <>
                  <ResultComulativeShow
                    key={index}
                    topic={item}
                    index={index}
                    lengthSum={0}
                    isDetail={isDetail}
                  />
                </>
              );
            })}
          </div>
        </Styles2>
      </>
    );
  return (
    <>
      <Styles
        header={t('Kết quả kiểm phiếu')}
        label={t('Kết quả biểu quyết tại Đại hội')}
      >
        <UITable
          items={topic2.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize,
          )}
          customHeader={<ResultHeaders />}
        >
          {(item, ind) => {
            const index = currentPage * pageSize - pageSize + ind;
            return <ResultItemShow key={index} index={index} topic={item} />;
          }}
        </UITable>
        <div className="pagination-cover">
          <Pagination
            // handleChangePageSize={getPageSize}
            hideSelectSize={true}
            current={currentPage}
            total={topic2.length}
            pageSize={pageSize}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </Styles>
      <Styles header={t('Bầu cử')}>
        {topic6.length > 0 && (
          <div className="topic topic6">
            <div className="content-top">
              <img
                src={conference.logoUrl || '/images/bvote_logo.png'}
                alt=""
              />
              <p className="title">{t('Kết quả biểu quyết tại Đại hội')}</p>
            </div>

            <div className="pagination-cover">
              <UITable
                items={topic6.slice(
                  (currentPage3 - 1) * pageSizeTopic6,
                  currentPage3 * pageSizeTopic6,
                )}
                customHeader={<ResultHeaders />}
              >
                {(item, ind) => {
                  const index =
                    currentPage3 * pageSizeTopic6 - pageSizeTopic6 + ind;
                  return (
                    <ResultItemShow key={index} index={index} topic={item} />
                  );
                }}
              </UITable>
              <Pagination
                // handleChangePageSize={getPageSize}
                hideSelectSize={true}
                current={currentPage3}
                total={topic6.length}
                pageSize={pageSizeTopic6}
                onChange={(page) => {
                  setCurrentPage3(page);
                }}
              />
            </div>
          </div>
        )}
        <div className="divider-comulative-vote"></div>
        <div className="topic">
          <div className="content-top">
            <img src={conference.logoUrl || '/images/bvote_logo.png'} alt="" />
            <p
              className="title title-show"
              onClick={() => setIsOpen(true)}
              style={{ marginTop: '20px' }}
            >
              {i18n.language === 'vi'
                ? topic4[currentPage2 - 1]?.name
                : topic4[currentPage2 - 1]?.name_2}
            </p>
          </div>

          <ResultComulativeShow
            index={currentPage2}
            lengthSum={dataShow.length || topic2.length}
            topic={topic4[currentPage2 - 1] || {}}
          />
          <div className="pagination-cover">
            <Pagination
              // handleChangePageSize={getPageSize}
              hideSelectSize={true}
              current={currentPage2}
              total={topic4.length}
              pageSize={1}
              onChange={(page) => {
                setCurrentPage2(page);
              }}
            />
          </div>

          {isOpen && (
            <PdfModal
              source={
                publicUrl(
                  i18n.language === 'vi'
                    ? topic4[currentPage2 - 1]?.fileUrl
                    : topic4[currentPage2 - 1]?.fileUrl_2,
                ) || ''
              }
              name={
                i18n.language === 'vi'
                  ? topic4[currentPage2 - 1]?.name
                  : topic4[currentPage2 - 1]?.name_2
              }
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
        </div>
      </Styles>
    </>
  );
};

export default SectionReport;

const Styles = styled(Card)`
  margin: 32px 0;

  p {
    color: ${(props) => props.theme.textColor || ''};
  }

  .title {
    font-size: 20px;
    padding-right: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: end;
    margin-bottom: 20px;

    &.title-show {
      cursor: pointer;
      &:hover {
        color: #00cb82;
        text-decoration: underline;
      }
    }
  }

  @media only screen and (display-mode: fullscreen) {
    .divider-comulative-vote {
      height: 500px;
    }
  }
`;

const Styles2 = styled(Card)`
  margin-bottom: 30px;
  width: 100%;
  /* padding: 40px; */
  /* background: rgba(255, 255, 255, 0.5); */
  h2,
  p {
    color: #3f475e;
  }
  @media only screen and (display-mode: fullscreen) {
    .topic {
      margin: 500px 0 !important;
      line-height: 2 !important;
    }
    .topic6 {
      margin-top: 0 !important;
    }
  }
  &.type2 {
    .head {
      padding-bottom: 0%;
    }
    .content {
      .report-item {
        background: #f2f7fd;
        padding: 28px;
      }
    }
  }

  .head {
    .title {
      font-size: 20px;
      line-height: 30px;
      color: #1d2e4b;
      font-family: oswald;
      margin-bottom: 78px;
    }
  }

  .content {
    background: white;
    padding: 98px 0 40px;
    border: 1px solid rgba(227, 232, 245, 1);
    position: relative;
    .info {
      max-width: 554px;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      top: -58px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      .info-row {
        margin: 0 -15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .info-col {
        padding: 0 15px;
        width: 50%;
      }
      .info-item {
        padding: 17px 33px;
        &.left {
          background: linear-gradient(135deg, #2bd25e 0%, #06c8df 100%);
          border-radius: 20px;
        }
        &.right {
          background: linear-gradient(135deg, #fdd819 0%, #e80505 100%);
          border-radius: 20px;
        }
        .value {
          font-size: 40px;
          line-height: 60px;
          color: #ffffff;
          margin-bottom: 0%;
          font-family: oswald;
        }
        .desc {
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0%;
        }
      }
    }

    .table-container {
      background: white;
      /* padding-top: 98px; */
      /* padding-left: 15px; */
      /* padding-right: 15px; */
      /* margin-top: -58px; */
      /* padding-bottom: 40px; */
      .table-wrap {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .card-content {
    .type2 {
      .head {
        .title {
          margin-bottom: 20px;
        }
      }
      .content {
        padding-top: 0px;
        background: none;
        border: none;
      }
    }
  }

  .topic6 {
    .head {
      .title {
        margin-bottom: 20px;
      }
    }
    .content {
      padding-top: 0px;
      background: none;
      border: none;
    }
  }

  .hidden {
    display: none;
  }
  @media only screen and (display-mode: fullscreen) {
    .title {
      display: block;
      text-align: center;
      margin: 0 0 16px;
      font-size: 24px;
      font-weight: 900;
      text-transform: uppercase;
      color: #3f475e;
    }
    .topic {
      margin: 500px 0 !important;
      line-height: 2 !important;
    }
    .topic6 {
      margin-top: 0 !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .content {
      padding: 98px 0 20px;
      .info {
        .info-item {
          padding: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    .content {
      .info {
        .info-row {
          margin: 0 -5px;
        }
        .info-col {
          padding: 0 5px;
        }
      }
    }
  }
`;
