export const formatNumber = (num, decimal) =>
  Number(num || 0).toLocaleString('de-DE', {
    maximumFractionDigits: decimal || 0,
  });

export const formatDateString = (dateString) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // If the date is invalid, assume the format is dd/MM/YYYY or dd-MM-YYYY and convert to MM/dd/yyyy
    const separator = dateString?.includes('/') ? '/' : '-';
    const [day, month, year] = dateString.split(separator);
    return `${month}/${day}/${year}`;
  }
  // If the date is valid, return the original string
  return dateString;
};
