import { changeLoading } from '@duck/pages/app/actions';
import { setAuthToken } from '@duck/pages/loginPage/actions';
import axios from 'axios';
import i18n from 'i18n';
import PNotify from 'pnotify/dist/es/PNotify';
import store from 'states/store';
import { t, translateError } from 'translate';
import * as constants from './constants';

const axiosInterceptor = axios.create();

axiosInterceptor.interceptors.request.use(
  function (config) {
    if (config?.loading !== false) store.dispatch(changeLoading(true));
    return config;
  },
  function (error) {
    // Do something with request error
    store.dispatch(changeLoading(false));
    return Promise.reject(error);
  },
);

axiosInterceptor.interceptors.response.use(
  function (config) {
    store.dispatch(changeLoading(false));
    return config;
  },
  async function (error) {
    // Do something with request error
    store.dispatch(changeLoading(false));
    const message = error?.response?.data?.message;
    const me = store.getState().login.userInfo;
    const method = error.config?.method?.toLowerCase();

    if (
      message == 'Unauthorized' &&
      error?.response?.data?.errorCode == 401 &&
      window.location.pathname != '/login'
    ) {
      localStorage.removeItem('token');
      store.dispatch(setAuthToken(''));
    }
    if (
      !(method === 'get' && !me?.conference) &&
      message !== 'Xác thực không hợp lệ.' &&
      error?.response?.data?.errorCode !== 401
    ) {
      PNotify.error({
        title: t('Lỗi'),
        text: await translateError(error),
        delay: 2000,
      });
    }

    return Promise.reject(error);
  },
);

export const callApi = (url, method, data, loading = true) => {
  const token = localStorage.getItem('token');
  const axiosInstance = axiosInterceptor;

  if (method.toLowerCase() === 'get') {
    return axiosInstance({
      method: method,
      url: `/api/${url}`,
      headers: {
        Authorization: token,
      },
      params: data,
      loading,
    });
  } else
    return axiosInstance({
      method: method,
      url: `/api/${url}`,
      headers: {
        Authorization: token,
      },
      data: data,
      loading,
    });
};

export const callResultApi = (url, method, data) => {
  const token = localStorage.getItem('token');
  return axios({
    method: method,
    url: `${constants.URL_RESULT}/api/${url}`,
    headers: {
      Authorization: token,
    },
    data: data,
  });
};

export const callDownloadApi = (url, method, data) => {
  const token = localStorage.getItem('token');
  return axiosInterceptor({
    method: method,
    responseType: 'arraybuffer',
    url: `/api/${url}`,
    headers: {
      Authorization: token,
      'Content-Type': 'blob',
    },
    data: data,
  });
};

export const callExportApi = (url, method, data) => {
  const token = localStorage.getItem('token');
  return axiosInterceptor({
    method: method,
    responseType: 'blob',
    url: `/api/${url}`,
    headers: {
      Authorization: token,
    },
    data: data,
  });
};

export const callDownloadPdfApi = (url, method, data) => {
  const token = localStorage.getItem('token');
  return axios({
    method: method,
    responseType: 'blob',
    url: `/api/${url}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    data: data,
  });
};

export const callLoginApi = (url, method, data, t, success) => {
  return axios({
    method: method,
    url: `/api/${url}`,
    data: data,
  })
    .then(function (res) {
      success(res);
    })
    .catch(function (err) {
      if (err) {
        if (err.response && err.response.data && err.response.data.message) {
          PNotify.error({
            title: t('Lỗi'),
            text: t(err.response.data.message.replace('.', '')),
            delay: 2000,
          });
        } else if (err.response) {
          PNotify.error({
            title: t('Lỗi'),
            text: t(err.response.statusText.replace('.', '')),
            delay: 2000,
          });
        }
      }
    });
};
