import Pagination from '@components/pagination';
import { UIButton } from 'components/bases/UI/Button';
import { UITable } from 'components/bases/UI/Table';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'translate/store';
import {
  getUserApi,
  getUserApiLoading,
  inviteSpeakApi,
  setFilter,
  setToggleModal,
} from '../../../states/duck/pages/result/resultReducer/actions';
import { transformNumberPrinter } from '../../bases/configs';
import { BsModal } from '../../bases/shared';
import { Card } from './sectionInfo';

const SectionComment = ({ isDetail, props }) => {
  const { i18n } = useTranslation();
  if (isDetail)
    return (
      <Styles1 header={t('Ý kiến Cổ đông')} {...props}>
        <Table />
      </Styles1>
    );
  else
    return (
      <Styles header={t('Ý kiến Cổ đông')} {...props}>
        <Table />
      </Styles>
    );
};

export default SectionComment;
const Styles1 = styled(Card)`
  .invite-btn {
    height: 24px;
    font-weight: 400;
    border-radius: 2px;
    white-space: nowrap;
  }
  h2,
  p {
    color: #3f475e;
  }
  table {
    width: 100%;
    /* border-collapse: separate;
    border-spacing: 2px; */
    thead {
      th {
        background: #718098;
      }
    }

    tbody {
      tr {
        :hover {
          color: #1d2e4b;
        }
        :nth-child(odd) {
          background: white !important;
        }
        td {
          color: #3f475e;
        }
      }
    }
  }
`;
const Styles = styled(Card)`
  .invite-btn {
    height: 24px;
    font-weight: 400;
    border-radius: 2px;
    white-space: nowrap;
  }
`;

const FormSearch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.result.result.filter);
  const [IDCard, setIDCard] = useState(filter.identityNumber);
  const onSubmit = () => {
    dispatch(setFilter({ ...filter, identityNumber: IDCard, pageNumber: 1 }));
    dispatch(
      getUserApiLoading({ ...filter, identityNumber: IDCard, pageNumber: 1 }),
    );
  };
  return (
    <div className="form-search">
      <div className="search-content">
        <input
          type="text"
          className="common__input"
          onChange={(e) => setIDCard(e.target.value)}
          placeholder={t('Nhập tên/CMND/CCCD')}
        />
        <button className="common__btn" onClick={onSubmit}>
          {t('Tìm kiếm')}
        </button>
      </div>
    </div>
  );
};

const Table = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const table = useSelector((state) => state.result.result.tableList);
  const toggleModal = useSelector((state) => state.result.result.toggleModal);
  const total = useSelector((state) => state.result.result.total);
  const filter = useSelector((state) => state.result.result.filter);
  const [itemProcess, setItemProcess] = useState({});
  useEffect(() => {
    dispatch(getUserApi(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    let t;
    t = setTimeout(() => {
      dispatch(getUserApi(filter));
    }, 30000);
    return () => {
      clearTimeout(t);
    };
  }, [dispatch, filter, table]);

  const openModal = (item) => {
    setItemProcess(item);
    dispatch(setToggleModal(true));
  };

  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    // const {identityNumber, joinType, status, scale, type} = filter;
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };

  const thead = [
    t('Stt'),
    t('Mã Cổ đông'),
    t('Tên Cổ đông'),
    t('CMND/CCCD'),
    t('Nội dung ý kiến'),
    t('Thời gian'),
    t('Số cổ phần'),
    t('Chức năng'),
  ];

  return (
    <Fragment>
      <>
        <UITable headers={thead} items={table}>
          {(item, index) => {
            return (
              <tr>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td>{item?.data?.shareholder?.displayCode}</td>
                <td>
                  {item.data && item.data.shareholder
                    ? item.data.shareholder.name
                    : ''}
                </td>
                <td>
                  {item.data && item.data.shareholder
                    ? item.data.shareholder.identityNumber
                    : ''}
                </td>
                <td
                  style={{
                    whiteSpace: 'normal',
                    textAlign: 'justify',
                  }}
                >
                  {item.data.opinion}
                </td>
                <td>
                  {`${new Date(item.updatedAt).toLocaleTimeString('it-IT')} 
                  ${formatDate(item.updatedAt)}`}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {item.data && item.data.shareholder
                    ? transformNumberPrinter(item.data.shareholder.sharesNumber)
                    : ''}
                </td>
                <td>
                  <UIButton
                    className="invite-btn"
                    outline
                    onClick={() => openModal(item)}
                  >
                    {t('Mời phát biểu')}
                  </UIButton>
                </td>
              </tr>
            );
          }}
        </UITable>
        {table.length > 0 && (
          <Pagination
            handleChangePageSize={getPageSize}
            current={filter.pageNumber}
            total={total}
            pageSize={filter.pageSize}
            onChange={getPage}
          />
        )}
      </>

      <BsModal
        closeIcon='<i class="far fa-window-close"></i>'
        toggleModal={toggleModal}
        onClick={() => dispatch(setToggleModal(false))}
        className="shareholder-relations"
      >
        {toggleModal ? <InviteSpeakModal itemProcess={itemProcess} /> : ''}
      </BsModal>
    </Fragment>
  );
};

const InviteSpeakModal = ({ itemProcess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shareholderId =
    itemProcess && itemProcess.data && itemProcess.data.shareholder
      ? itemProcess.data.shareholder._id
      : '';

  const onSubmit = () => {
    dispatch(
      inviteSpeakApi({
        shareholderId,
        t,
      }),
    );
  };
  return (
    <div className="modal-content">
      <div className="head">
        <p className="title">
          {t('Bạn đang mời Cổ đông')} {itemProcess.data.shareholder.name}{' '}
          {t('phát biểu ý kiến')}
        </p>
      </div>
      <div className="content">
        <div className="report-delete">
          <p className="desc">{t('Bạn có chắc chắn với hành động này?')}</p>
          <button className={`common__btn`} onClick={onSubmit}>
            {t('Chắc chắn')}
          </button>
        </div>
      </div>
    </div>
  );
};

const formatDate = (date) => {
  if (date) {
    const d = new Date(date);
    const dtf = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [{ value: mo }, , { value: da }, , { value: ye }] =
      dtf.formatToParts(d);
    return `${da}/${mo}/${ye}`;
  }
  return '';
};
