import React, { useRef, useState } from 'react';
import { Styles } from './styles';
import Section from '@components/section';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import QRCode from 'qrcode';

const ConfigQRCode = () => {
  const [url, setUrl] = useState('');
  const [qrValue, setQrValue] = useState('');

  const handleGenerateQR = () => {
    QRCode.toDataURL(
      url,
      {
        width: 300,
        margin: 2,
      },
      (err, url) => {
        if (err) return console.log(err);
        setQrValue(url);
      },
    );
  };

  return (
    <Styles>
      <Section header={'Cấu hình QR Code'}>
        <div className="module">
          <div className="module-content">
            <div className="form">
              <div className="title">Nhập website (URL)</div>

              <input
                type="text"
                placeholder="Đường dẫn"
                onChange={(e) => setUrl(e.target.value)}
              />
              <Button onClick={handleGenerateQR}>
                <span>{inputIcons.edit}</span>
                Tạo QR code
              </Button>
            </div>
          </div>
          <div className="module-qr">
            <div className="qr-code">
              <img src={qrValue} />
            </div>
            <a href={qrValue} download="qrcode.png">
              <Button>
                <span>{inputIcons.download}</span> Tải về
              </Button>
            </a>
          </div>
        </div>
      </Section>
    </Styles>
  );
};

export default ConfigQRCode;
