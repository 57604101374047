import Button from '@components/button';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import Modal from '@components/modal';
import Pagination from '@components/pagination';
import Table from '@components/table';
import { defaultOption } from '@configs/defaultOption';
import {
  blockShareholderApi,
  getShareholderApi,
  getShareholderBlockApi,
  setFilter,
} from '@duck/pages/admin/initializeReportReducer/actions';
import { SDisableHolderModal } from '@styled/admin/initializeReportPage';
import React, { Children, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { disableHolderTheads, holderTypeOptions } from '../data';
import { transformNumberPrinter } from '@configs/transformNumber';
import { useTranslation } from 'react-i18next';

export default function DisableHolderModal({
  isOpen,
  toggleModal,
  title,
  id,
  subtitle,
  kind,
  tableType,
  method,
}) {
  const { register, control, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = (data) => {
    dispatch(
      setFilter({
        ...data,
        shareholderType: data.shareholderType.value,
        pageNumber: 1,
      }),
    );
  };

  // set default shareholder type
  useEffect(() => {
    setValue(
      'shareholderType',
      holderTypeOptions.filter((t) => t.value === defaultOption.value)[0],
    );
  }, [setValue]);
  return (
    <Modal {...{ isOpen, toggleModal, title, subtitle }}>
      <SDisableHolderModal>
        <forn className="form">
          <div className="form__item">
            <Input.Text
              register={register('identityNumber')}
              wrapperProps={{
                icon: inputIcons.account,
                label: t('Chọn Cổ đông chặn'),
              }}
            />
          </div>
          <div className="form__item">
            <Input.Select
              name="shareholderType"
              selectProps={{ options: holderTypeOptions }}
              control={control}
            />
          </div>
          <div className="form__item">
            <Button
              style={{ borderRadius: 0, width: '100%' }}
              onClick={handleSubmit(onSubmit)}
            >
              {t('Tìm kiếm')}
            </Button>
          </div>
        </forn>
        {isOpen && (
          <DataTable {...{ id, kind }} tableType={tableType} method={method} />
        )}
      </SDisableHolderModal>
    </Modal>
  );
}
// table
function DataTable({ id, kind, tableType, method }) {
  const { shareholderList, total, filter, shareholderRefetch } = useSelector(
    (state) => state.admin.initializeReport,
  );
  const { t } = useTranslation();

  const {
    identityNumber,
    joinType,
    status,
    pageSize,
    pageNumber,
    shareholderType,
  } = filter;

  const dispatch = useDispatch();

  // get data
  useEffect(() => {
    if (shareholderType === defaultOption.value) {
      dispatch(
        getShareholderApi({
          identityNumber,
          joinType,
          status,
          pageSize,
          pageNumber,
        }),
      );
    } else if (shareholderType === 'blocked') {
      dispatch(
        getShareholderBlockApi(
          {
            identityNumber,
            joinType,
            status,
            pageSize,
            pageNumber,
          },
          id,
          kind,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, shareholderRefetch]);

  return (
    <Table header={disableHolderTheads}>
      {Children.toArray(
        shareholderList.map((s) => (
          <TableRow
            d={s}
            id={id}
            kind={kind}
            tableType={tableType}
            method={method}
          />
        )),
      )}
      <tr>
        <td colSpan={100} className="center1">
          {shareholderList.length > 0 ? (
            <Pagination
              handleChangePageSize={(pageSize) => {
                dispatch(setFilter({ ...filter, pageSize }));
              }}
              current={pageNumber}
              pageSize={pageSize}
              onChange={(pageNumber) =>
                dispatch(setFilter({ ...filter, pageNumber }))
              }
              total={total}
            />
          ) : (
            t('Chưa có dữ liệu')
          )}
        </td>
      </tr>
    </Table>
  );
}

const TableRow = ({ d, id, kind, tableType, method }) => {
  const { blockList } = useSelector((state) => state.admin.initializeReport);
  // const blocked = d && d.shareholderType === 'blocked';
  const { t } = useTranslation();
  const blocked =
    blockList.findIndex((item) => item === d._id) >= 0 ? true : false;
  const dispatch = useDispatch();

  const onDisableHolder = () => {
    if (d) {
      const shareholderIds = [...blockList];
      const index = blockList.findIndex((item) => item === d._id);
      if (index >= 0) shareholderIds.splice(index, 1);
      else shareholderIds.push(d._id);
      dispatch(
        blockShareholderApi(
          {
            topicId: id,
            shareholderIds,
          },
          tableType === 'sheet-4'
            ? method === 1
              ? 'sheet-4'
              : 'sheet-6'
            : tableType,
          kind,
        ),
      );
    }
  };

  return (
    <tr>
      <td>{d && d.name}</td>
      <td>{d && d.identityNumber}</td>
      <td>{d && d.phone}</td>
      <td>{d && transformNumberPrinter(d.sharesNumber)}</td>
      <td className="center">
        <Button
          style={{
            width: 80,
            height: 28,
            backgroundColor: blocked ? 'white' : '#FF6C6C',
            border: `1px solid #FF6C6C`,
            padding: 0,
            borderRadius: 0,
            fontWeight: 500,
            color: blocked ? '#FF6C6C' : 'white',
          }}
          onClick={onDisableHolder}
          // onClick={!blocked ? onDisableHolder : () => {}}
        >
          {blocked ? t('Bỏ chặn') : t('Chặn')}
        </Button>
      </td>
      <td>{blocked ? t('Đã chặn') : t('Chưa chặn')}</td>
    </tr>
  );
};
